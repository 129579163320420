import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Avatar from '../components/Avatar';

const avatarsStyle = () => {
    const array = Array.from(Array(3), (_, i) => i);
    return array.reduce((acc, num) => {
        acc[`&:nth-child(${num + 1})`] = { right: num * 12 };
        return acc;
    }, {});
};

const useStyles = makeStyles(() => ({
    panel: {
        width: 0,
        position: 'absolute',
        zIndex: 2,
        bottom: 8,
        right: 2
    },
    avatarWrapper: {
        position: 'absolute',
        ...avatarsStyle()
    },
    avatar: {
        width: 17,
        height: 17,
        fontSize: 17 / 2.5
    }
}));

const MessageRead = ({ lastRead, users }) => {
    const classes = useStyles();
    const avatars = lastRead.map((roomID) => ({ avatar: users[roomID].avatar, id: roomID }));

    return (
        <div className={classes.panel}>
            {avatars.map(({ avatar, id }) => (
                <div className={classes.avatarWrapper} key={id}>
                    <Avatar avatar={avatar} className={classes.avatar} key={id} />
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: state.chatUser.users
});

export default connect(mapStateToProps)(MessageRead);
