import { useEffect } from 'react';

/**
 * @category Hooks
 * @subcategory Shared
 * @function useHandleCLose
 * @param {boolean} isOpen - informacja czy komponent jest aktualnie otwarty
 * @param {Function} toggleOpen - funkcja do zamykania i otwierania komponentu
 * @param {string} id - id zamykanego komponentu
 * @description Hook który pozwala zamykać obsługiwane komponenty przez naciśnięcie klawisza escape lub kliknięcie poza nimi
 * @returns {void}
 */
export default function useHandleCLose(isOpen, toggleOpen, id) {
    useEffect(() => {
        function handleEscapeKey(event) {
            if (isOpen && event.key === 'Escape') toggleOpen();
        }

        function handleClickAway({ target }) {
            const container = document.querySelector(`#${id}`);
            if (!isOpen) return;
            if (!container || container.contains(target)) return;
            toggleOpen();
        }
        if (isOpen) {
            window.addEventListener('keydown', handleEscapeKey);
            document.addEventListener('click', handleClickAway);
        }

        return () => {
            window.removeEventListener('keydown', handleEscapeKey);
            document.removeEventListener('click', handleClickAway);
        };
    }, [isOpen, toggleOpen, id]);
}
