import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@mui/material/Badge';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        margin: 0,
        color: (props) => (props.active ? palette.grey[800] : palette.grey[500]),
        fontFamily: '"Lato", sans-serif',
        fontSize: spacing(1.8),
        lineHeight: `${spacing(2.2)}px`,
        wordSpacing: 1.2,
        fontWeight: 400,
        maxWidth: spacing(22.5),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    badge: {
        '& .MuiBadge-badge': {
            top: spacing(0.2),
            right: -spacing(1),
            backgroundColor: (props) => (props.active ? palette.info.main : palette.info.light),
            opacity: (props) => (props.newMsgInfo ? 0 : 1)
        }
    },
    msgIcon: {
        marginRight: spacing(0.4),
        color: palette.primary.light,
        opacity: (props) => (props.newMessage ? 1 : 0)
    }
}));

/**
 * Stanowy komponent funkcyjny. Wyświetla text w headerze okna czatu.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.activeStatus - informacja czy użytkownik jest aktywny
 * @param {ReactComponent[]|ReactComponent} props.children - komponenty dzieci.
 * @param {boolean} props.newMessage - informacja czy są w czacie wiadomości, których użytkownik mógł nie widzieć.
 * @param {boolean} props.active - informacja czy dane okno jest aktywne
 * @returns {ReactComponent}
 */
const HeaderTitle = ({ activeStatus, children, newMessage, active, newMsgInfo }) => {
    const classes = useStyles({ active, newMessage, newMsgInfo });

    return (
        <div className={classes.root}>
            {/* <MessageIcon fontSize="default" className={classes.msgIcon} /> */}
            <Badge className={classes.badge} variant="dot" invisible={!activeStatus}>
                <h2 className={classes.text}>{children}</h2>
            </Badge>
        </div>
    );
};

export default HeaderTitle;
