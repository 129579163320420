import { useState, useCallback, useEffect } from 'react';
import { minimizedWindowsKey } from '../../chat/constants/storageKeys';

/**
 * @category Hooks
 * @function useWindowsState
 * @description Hook wyciąga z sessionStorage stan zminimalizowania okna. Dodatkowo zapisuje w storage nową wartość przy aktualizacji
 * @param {string|number} windowID - id okna,
 * @returns {Array<boolean, Function>} - wartość dla id okna i funkcja do ustawiania wartości.
 */
export default function useWindowsState(windowID, setShowContent) {
    const minimizedWindows = JSON.parse(sessionStorage.getItem(minimizedWindowsKey)) || [];
    const isWindowMinimized = minimizedWindows.indexOf(windowID) > -1;
    const [minimized, setMinimized] = useState(isWindowMinimized);

    // remove minimized window when closing window or chat
    useEffect(() => {
        return () => {
            let minimizedWindows = JSON.parse(sessionStorage.getItem(minimizedWindowsKey)) || [];
            minimizedWindows = minimizedWindows.filter((id) => id !== windowID);
            minimizedWindows.length
                ? sessionStorage.setItem(minimizedWindowsKey, JSON.stringify(minimizedWindows))
                : sessionStorage.removeItem(minimizedWindowsKey);
        };
    }, [windowID]);

    useEffect(() => {
        if (windowID && typeof setShowContent === 'function') {
            const minimizedWindows = JSON.parse(sessionStorage.getItem(minimizedWindowsKey)) || [];
            const isWindowMinimized = minimizedWindows.indexOf(windowID) > -1;
            if (isWindowMinimized) {
                setShowContent(false);
            }
        }
    }, [setShowContent, windowID]);

    const toggleMinimize = useCallback(() => {
        let minimizedWindows = JSON.parse(sessionStorage.getItem(minimizedWindowsKey)) || [];
        minimizedWindows = minimized
            ? minimizedWindows.filter((id) => windowID !== id)
            : [...minimizedWindows, windowID];

        sessionStorage.setItem(minimizedWindowsKey, JSON.stringify(minimizedWindows));

        setMinimized((prev) => !prev);
    }, [setMinimized, minimized, windowID]);

    return [minimized, toggleMinimize];
}
