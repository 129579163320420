import React from 'react';

import ChatWindow from '../containers/ChatWindow';
import HiddenChatsPanel from './HiddenChatsPanel';
import { computeMaxWindows } from '../../shared/utils';

/**
 * Bezstanowy komponent funkcyjny. Decyduje na podstawie wielkości viewportu które okna czatu powinny zostać wyświetlone, a które ukryte.
 * Wyświetla komponenty [Components/Chat/HiddenChatsPanel]{@link HiddenChatsPanel}, [Components/Chat/ChatWindow]{@link ChatWindow}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {string[]} props.openWindows - Tablica id otwartych okien
 * @param {"mobile" | "medium" | "large" | "xlarge"} props.viewport string z wielkością dostępnego viewportu
 * @param {Function} props.setHiddenWindows - funkcja ustawiania ukrytych okien
 * @param {string[]} props.windowsNewMsg - informacja o oknach czatu z wiadomościami które mogły zostać nieodczytane.
 * @property {number} windowsCount - obliczona ilość możliwych do wyświetlenia okien
 * @property {string[]} visibleWindows - tablica id oknien do wyświetlenia
 * @property {string[]} hiddenWindows - tablica id oknien do ukrycia
 * @returns {ReactComponent}
 * @see  [Components/Chat/HiddenChatsPanel]{@link HiddenChatsPanel}, [Components/Chat/ChatWindow]{@link ChatWindow}
 */
const ChatWindowsList = ({ openWindows, viewport, setHiddenWindows, windowsNewMsg }) => {
    const windowsCount = computeMaxWindows(viewport);

    const visibleWindows = openWindows
        .slice(0, windowsCount)
        .map((id, i) => <ChatWindow key={id} id={id} windowOrder={i + 1} />);
    const hiddenWindows = openWindows.slice(windowsCount);

    return (
        <>
            {visibleWindows}
            <HiddenChatsPanel
                hiddenWindows={hiddenWindows}
                position={windowsCount}
                setHiddenWindows={setHiddenWindows}
                windowsNewMsg={windowsNewMsg}
            />
        </>
    );
};

export default ChatWindowsList;
