import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import CreateGroupModal from './CreateGroupModal';
import SelectedUsersList from './SelectedUsersList';
import AddUser from './AddUser';
import {
    usersToArray,
    addAvatarToUser,
    getAvailableGroupUsers,
    bindWindowCallback,
    areTwoArraysDifferent
} from '../../shared/utils';
import { emitSocketEvent } from '../sockets';
import { useEffect } from 'react';

const useStyles = makeStyles(({ palette }) => ({
    header: {
        padding: 23,
        paddingBottom: 0,
        flexGrow: 0,
        borderBottom: '2px solid ' + palette.grey[200],
        color: palette.secondary.dark,
        '& input': {
            color: palette.secondary.dark,
            margin: 0,
            textAlign: 'center',
            fontSize: 24,
            textTransform: 'uppercase',
            fontWeight: 400,
            fontFamily: '"Lato", sans-serif',
            border: 'none',
            backgroundColor: 'transparent !important',
            cursor: (props) => (props.isMygroup ? 'pointer' : 'initial'),
            '&:focus': {
                border: 'none !important',
                boxShadow: 'none !important',
                outline: 'none'
            }
        }
    },
    content: {
        padding: 12,
        flexGrow: 1
    },
    footer: {
        position: 'relative',
        borderTop: '1px solid ' + palette.grey[200],
        padding: 12,
        flexGrow: 0,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    list: {
        width: '100%'
    },
    okButton: {
        boxShadow: 'none',
        margin: '0 4px',
        color: palette.common.white,
        background: palette.info.dark,
        '&:hover': {
            boxShadow: 'none',
            background: palette.info.dark
        }
    },
    addUserButton: {
        boxShadow: 'none',
        margin: '0 4px',
        color: palette.common.white,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    delButton: {
        boxShadow: 'none',
        margin: '0 4px',
        color: palette.common.white,
        background: palette.primary.main,
        '&:hover': {
            boxShadow: 'none',
            background: palette.primary.dark
        }
    },

    errorMessage: {
        fontFamily: '"Lato", sans-serif',
        fontSize: 12,
        color: (props) => (props.isValid ? 'transparent' : palette.error.dark),
        marginTop: 5,
        marginBottom: 5,
        pointerEvents: 'none'
    }
}));

/**
 * Stanowy komponent funkcyjny podłączony do Redux Store. Wyświetla panel edycji istniejącej grupy.
 * Wyświetla komponenty  [Components/Chat/CreateGroupModal]{@link CreateGroupModal}, [Components/Chat/AddUser]{@link AddUser}, [Components/Chat/SelectedUsersList]{@link SelectedUsersList}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {object} props.group - Obiekt wybranej grupy. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.open - wyznacza czy panel jest otwarty
 * @param {Function} props.handleClose - funkcja zamyka panel.
 * @param {object|null} props.self - obiekt z informacjami o użytkowniku
 * @param {string} props.self.name - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.self.activeStatus - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.self.id - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {string} props.self.roomID - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {Function} props.setCallback - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.setLoadingState - zobacz [setLoadingState]{@link ActionCreators.setLoadingState}
 * @param {Function} props.setChatWindowLoading - zobacz [setChatWindowLoading]{@link ActionCreators.setChatWindowLoading}
 * @param {string[]} groupNames - Tablica nazw dostępnych użytkownikowi grup.
 * @property {string} name - stan nazwy grupy
 * @property {Function} setName - Funkcja ustawiania name.
 * @property {boolean} addUserOpen - stan otwarcia listy AddUser.
 * @property {Function} setAddUserOpen - Funkcja ustawiania addUserOpen.
 * @property {object[]} selectedUsers - stan listy użytkowników  dodanych do grupy.
 * @property {Function} setSelectedUsers - Funkcja ustawiania selectedUsers.
 * @property {boolean} isValid - stan poprawności nazwy grupy.
 * @property {Function} setIsValid - Funkcja ustawiania isValid.
 * @property {object[]} filteredUsers - lista użytkowników odfiltrowanych przez tych dodanych do grupy
 * @property {boolean} isMygroup - informacja czy grupa została stworzona przez aktywnego użytkownika
 * @returns {ReactComponent}
 * @see [Components/Chat/CreateGroupModal]{@link CreateGroupModal}, [Components/Chat/AddUser]{@link AddUser}, [Components/Chat/EditGroup]{@link SelectedUsersList}, [Components/Chat/SelectedUsersList]{@link SelectedUsersList}
 */
const EditGroup = ({
    group,
    open,
    handleClose,
    users,
    self,
    setCallback,
    groupNames,
    setLoadingState,
    setChatWindowLoading
}) => {
    const [name, setName] = useState('');
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const isMygroup = group && self && group.creator === self.id;
    const filteredUsers = users && group?.users && getAvailableGroupUsers(selectedUsers, users);

    const classes = useStyles({ isMygroup, isValid });

    /**
     * @memberof EditGroup
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook ustawia name na nazwe grupy i selectedUsers na użytkowników grupy.
     */
    useEffect(() => {
        if (group) {
            setName(group.name);
            setSelectedUsers(
                [addAvatarToUser(self, -1)].concat(
                    ...usersToArray(users).filter((u) => group.users.indexOf(u.id) !== -1)
                )
            );
        }
    }, [group, self, users]);

    /**
     * @memberof EditGroup
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook resetuje stan komponentu gdy jest on zamykany.
     */
    useEffect(() => {
        if (!open) {
            setAddUserOpen(false);
            group && setName(group.name);
            setSelectedUsers([]);
            setIsValid(true);
        }
    }, [open, group]);

    /**
     * @memberof EditGroup
     * @method hadnleDelete
     * @description Wywołuje dialog z potwierdzeniem i ustawia jako callbacki akcje zamknięcia panelu i usunięcia grupy. Wywołuje [client_delete_group]{@link SocketClientEvents.client_delete_group}
     * @return {void}
     */
    const hadnleDelete = () => {
        bindWindowCallback(() => {
            setLoadingState(true);
            setChatWindowLoading(group.roomID, true);
        });
        setCallback(() => {
            handleClose();
            emitSocketEvent('delete_group', { roomID: group.roomID });
        });
    };

    /**
	* @memberof EditGroup
	* @method handleRemoveSelf
	* @description Wywołuje dialog z potwierdzeniem i ustawia jako callbacki akcje zamknięcia panelu i usunięcia siebie z grupy. Wywołuje [client_remove_self_from_group]{@link SocketClientEvents.client_remove_self_from_group} lub [client_delete_group]{@link SocketClientEvents.client_delete_group} w zależności od
	tego czy użytkownik jest ostatnim w grupie.
	* @return {void}
	*/
    const handleRemoveSelf = () => {
        bindWindowCallback(() => {
            setLoadingState(true);
            setChatWindowLoading(group.roomID, true);
        });

        setCallback(() => {
            handleClose();
            if (group.users.length === 1) {
                emitSocketEvent('delete_group', { roomID: group.roomID });
            } else {
                emitSocketEvent('remove_self_from_group', { roomID: group.roomID });
            }
        });
    };

    /**
     * @memberof EditGroup
     * @method addUser
     * @description Dodaje użytkownika do selectedUsers.
     * @return {void}
     */
    const addUser = (user) => setSelectedUsers((prev) => [...prev, user]);

    /**
     * @memberof EditGroup
     * @method removeUser
     * @description Usuwa użytkownika z selectedUsers.
     * @return {void}
     */
    const removeUser = (roomID) =>
        setSelectedUsers((prev) => prev.filter((pu) => pu.id !== roomID));

    /**
     * @memberof EditGroup
     * @method handleAddUserOpen
     * @description Otwiera listę z użytkownikami, jeśli są jacyś do dodania.
     * @return {void}
     */
    const handleAddUserOpen = (event) => {
        if (Object.keys(filteredUsers).length) {
            // event propagate at bubbling phrase to window after AddUser subscribed to window click
            // by useHandleCLose, other solution is to give little timeout on setSettingsOpen
            event.stopPropagation();

            setAddUserOpen(true);
        }
    };

    /**
     * @memberof EditGroup
     * @method handleOk
     * @description Obsługuje przycisk ok/anuluj wykonuje akcje w zależności od uprawnień i stanu komponentu. Wywołuje [client_modify_group]{@link SocketClientEvents.client_modify_group}
     * @return {void}
     */
    const handleOk = () => {
        const selectedIDs = selectedUsers.map((u) => u.id);
        /* name must be valid, name or users need to be changed, must be at least two group users   */
        if (
            isValid &&
            (areTwoArraysDifferent(group.users, selectedIDs) || group.name !== name) &&
            selectedIDs.length > 1
        ) {
            /* Set variables either to value or false if condition is not met */
            const modifiedName = group.name !== name && name;
            const modifiedUsers = areTwoArraysDifferent(group.users, selectedIDs) && selectedIDs;

            /* redux actions must be binded to window function, because they cant be called from reducer */
            bindWindowCallback(() => {
                setLoadingState(true);
            });

            /* that will be call from reducer */
            setCallback(() => {
                handleClose();
                emitSocketEvent('modify_group', {
                    roomID: group.roomID,
                    modifiedName,
                    modifiedUsers
                });
            });

            return;
        }
        /* if conditions not met, just close pop up window */
        handleClose();
    };

    /**
     * @memberof EditGroup
     * @method handleSelectedUsersClick
     * @param {string} roomID identyfikator grupy
     * @description Obsługuje przycisk obok użytkownika, może wywołać handleRemoveSelf lub removeUser w zależności od rodzaju użytkownika.
     * @return {void}
     */
    const handleSelectedUsersClick = (roomID) => {
        if (roomID === self.roomID) {
            handleRemoveSelf();
            return;
        }

        removeUser(roomID);
    };

    /**
     * @memberof EditGroup
     * @method checkValidity
     * @description Sprawdza poprawność wybranej nazwy.
     * @return {void}
     */
    const checkValidity = (name) =>
        name.length < 16 && name.length > 2 && !groupNames.includes(name.toLowerCase());

    /**
     * @memberof EditGroup
     * @method handleInputChange
     * @param {Event} e  event
     * @description Obsługuje event zmiany nazwy grupy.
     * @return {void}
     */
    const handleInputChange = (e) => {
        const ok = checkValidity(e.target.value);
        !isValid && ok && setIsValid(true);
        isValid && !ok && setIsValid(false);
        setName(e.target.value);
    };

    return (
        <CreateGroupModal open={open} handleClose={handleClose}>
            <div className={classes.header}>
                <input
                    className={classes.title}
                    disabled={!isMygroup}
                    onChange={handleInputChange}
                    value={name}
                />
                <p className={classes.errorMessage}>
                    Nazwa musi mieć od 3 do 15 liter i nie powtarzać się
                </p>
            </div>

            <div className={classes.content}>
                <div className={classes.list}>
                    <SelectedUsersList
                        handleClick={handleSelectedUsersClick}
                        users={selectedUsers || []}
                        edit
                        isMygroup={isMygroup}
                    />
                </div>
            </div>
            <div className={classes.footer}>
                {isMygroup && (
                    <>
                        <AddUser
                            position={{ bottom: 58, left: 5 }}
                            toggleOpen={() => setAddUserOpen(false)}
                            open={addUserOpen}
                            users={filteredUsers}
                            addUser={addUser}
                        />
                        <Button
                            variant="contained"
                            className={classes.addUserButton}
                            onClick={handleAddUserOpen}
                            color="secondary">
                            dodaj osoby
                        </Button>
                        <Button
                            className={classes.delButton}
                            onClick={hadnleDelete}
                            variant="contained">
                            Skasuj grupę
                        </Button>
                    </>
                )}
                <Button className={classes.okButton} onClick={handleOk} variant="contained">
                    {(isValid && selectedUsers.length > 1) || !isMygroup ? 'OK' : 'ANULUJ'}
                </Button>
            </div>
        </CreateGroupModal>
    );
};

export default EditGroup;
