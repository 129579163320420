import React, { useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Spinner from '../../shared/components/Spinner';
import { emitSocketEvent } from '../sockets';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        flexGrow: 1,
        height: 0
    },
    wrapper: {
        height: '100%'
    },
    inner: {
        position: 'relative',
        height: '100%'
    },
    content: {
        overflowY: 'scroll',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: spacing(1),
        paddingBottom: 0,
        '& > :last-child': {
            paddingBottom: spacing(1)
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny podłączony do Redux Strore. Wyświetla okno konkretnego czatu.
 * Wyświetla komponenty: [Components/Shared/Spinner]{@link Spinner}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {ReactComponent[]|ReactComponent} props.children - komponenty dzieci (Wiadomości)
 * @param {Function} props.scrollToBottom - Funkcja odpowiada za przeskrolowanie okna na dół po załadowaniu wiadomości
 * @param {number|null} props.firstLoaded -  Zobacz index w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {string} props.roomID - id czatu
 * @param {boolean} props.scrolledUp -  Zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {Function} props.setScrolledUp - zobacz [setScrolledUp]{@link ActionCreators.setScrolledUp}
 * @param {number} props.bottomOffset - zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {Function} props.appLoading - stan ładowania aplikacji. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.handleFile - funkcja obsługująca upload pliku
 * @param {ReactRef} props.boardRef - referencja do elementu html
 * @param {boolean} props.loading - stan ładowania okna. Zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {number} props.chatID - id czatu jesli został utworzony. Zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {Function} props.setChatWindowLoading - zobacz [setChatWindowLoading]{@link ActionCreators.setChatWindowLoading}
 * @property {boolean} minimized - stan rozwinięcia listy z użytkownikami.
 * @property {Function} setMinimized - Funkcja ustawiania usersOpen.
 * @property {boolean} showContent -  stan pomocniczy minimalizacji okna, używany w css
 * @property {Funtion} setShowContent - funckja ustawiania showContent
 * @returns {ReactComponent}
 * @see [Components/Shared/Spinner]{@link Spinner}
 */
const MessagesBoard = ({
    children,
    scrollToBottom,
    firstLoaded,
    roomID,
    scrolledUp,
    setScrolledUp,
    bottomOffset,
    appLoading,
    handleFile,
    boardRef,
    loading,
    chatID,
    setChatWindowLoading
}) => {
    const classes = useStyles();
    const countRef = useRef(0);
    /**
     * @memberof MessagesBoard
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Kiedy zmieniają się elementy dzieci (pojawiają się nowe wiadomości) okno zostanie przeskrolowane na odpowiednią wysokość.
     */
    useEffect(() => {
        if (children.length && bottomOffset) {
            const el = boardRef.current;
            const elHeight = el.clientHeight;
            const elTotalHeight = el.scrollHeight;

            const scrollTop = elTotalHeight - elHeight - bottomOffset;
            el.scrollTop = scrollTop;
        }
    }, [children, bottomOffset, boardRef]);

    /**
     * @memberof MessagesBoard
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook odpowiada za skrolowanie okna, ustawia odpowiednie flagi a także wysyła request o doładowanie nowych wiadomości. Wywołuje [client_load_messages]{@link SocketClientEvents.client_load_messages}.
     */
    useEffect(() => {
        const el = boardRef.current;
        const elHeight = el.clientHeight;
        const elTotalHeight = el.scrollHeight;
        const elLastChild = el.lastElementChild;

        if (!scrolledUp && el && elLastChild) {
            scrollToBottom();
        }

        function handleScroll() {
            const scrollTop = el.scrollTop;
            const scrolled = elHeight + scrollTop < elTotalHeight - 20;

            if (scrollTop === 0 && firstLoaded) {
                const bottomOffset = el.scrollHeight - el.scrollTop - el.clientHeight;
                const errorAction = () => setChatWindowLoading(roomID, false);

                setChatWindowLoading(roomID, true);
                emitSocketEvent(
                    'load_messages',
                    { messageID: firstLoaded, roomID, bottomOffset },
                    { errorAction }
                );
            }

            if (!scrolledUp && scrolled) {
                setScrolledUp(roomID, true);
            } else if (scrolledUp && !scrolled) {
                setScrolledUp(roomID, false);
            }
        }

        el.addEventListener('scroll', handleScroll);

        return () => el.removeEventListener('scroll', handleScroll);
    }, [
        scrolledUp,
        children,
        firstLoaded,
        roomID,
        scrollToBottom,
        setScrolledUp,
        boardRef,
        setChatWindowLoading
    ]);

    /**
     * @memberof MessagesBoard
     * @method handleDragEnter
     * @param {Event} e - event
     * @description Obsługuje event "ondragenter".
     * @return {void}
     */
    const handleDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!chatID) {
            return;
        }

        countRef.current += 1;

        if (countRef.current > 0) {
            e.currentTarget.style.opacity = 0.5;
            e.currentTarget.style.background = 'rgb(191,191,214)';
        }
    };

    /**
     * @memberof MessagesBoard
     * @method handleDragOver
     * @param {Event} e - event
     * @description Obsługuje event "ondragover".
     * @return {void}
     */
    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    /**
     * @memberof MessagesBoard
     * @method handleDragLeave
     * @param {Event} e - event
     * @description Obsługuje event "ondrop". Procesuje przekazany plik i wysyła go na serwer
     * @return {void}
     */
    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!chatID) {
            return;
        }

        countRef.current = 0;
        e.currentTarget.style.background = '';
        e.currentTarget.style.opacity = '';

        if (!loading && !appLoading) {
            const file = e.dataTransfer.files[0];
            handleFile(file, roomID);
        }
    };

    /**
     * @memberof MessagesBoard
     * @method handleDragLeave
     * @param {Event} e - event
     * @description Obsługuje event "ondragleave".
     * @return {void}
     */
    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!chatID) {
            return;
        }

        countRef.current -= 1;
        if (countRef.current === 0) {
            e.currentTarget.style.background = '';
            e.currentTarget.style.opacity = '';
        }
    };
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.inner}>
                    {(loading || appLoading) && <Spinner />}
                    <div
                        ref={boardRef}
                        className={classes.content}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onDragLeave={handleDragLeave}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessagesBoard;
