import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';

import Avatar from '../components/Avatar';

const useStyles = makeStyles(() => ({
    root: {
        maxHeight: (props) => (props.edit ? 250 : 170),
        overflowY: 'auto'
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla Listę użytkowników grupy użytkowników.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {object[]} props.users - lista z użytkownikami grupy. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Function} props.handleClick - funkcja obsługuje kliknięcie.
 * @param {boolean} props.edit - zmienna definiuje czy komponent dotyczy edycji czy tworzenia grupy.
 * @param {boolean} props.isMygroup - zmienna definiuje czy grupa została stworzona przez aktualnego użytkownika.
 * @returns {ReactComponent}
 * @see [Components/Chat/Avatar]{@link Avatar}
 */
const SelectedUsersList = (props) => {
    const { users, handleClick, edit = false, isMygroup } = props;
    const classes = useStyles({ edit });

    return (
        <List className={classes.root}>
            {users.map((user, i) => (
                <ListItem key={user.id} selected={edit ? i === 0 : Boolean(i % 2)}>
                    <ListItemAvatar>
                        <Avatar avatar={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText>{user.name}</ListItemText>
                    {(!edit || (edit && i !== 0 && isMygroup)) && (
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={() => handleClick(user.id)}
                                color="primary"
                                size="small">
                                <ClearIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                    {edit && i === 0 && !isMygroup && (
                        <ListItemSecondaryAction>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => handleClick(user.id)}>
                                Opuść grupę
                            </Button>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))}
        </List>
    );
};

export default SelectedUsersList;
