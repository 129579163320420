import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import GroupIcon from '@material-ui/icons/Forum';
import SettingsIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import withMainPanelList from '../HOC/withMainPanelList';
import { getUnreadMessagesCount, isGroupIdTemporary } from '../../shared/utils';
import MessageIcon from './MessageIcon';

/**
 * Bezstanowy komponent funkcyjny. Wyświetla listę z grupami użytkownika
 * Przekazywany do HOC:  [Components/Chat/withMainPanelList]{@link withMainPanelList}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Object.<string,string>} props.classes - nazwy klas w css
 * @param {Object.<string,object>} props.groups - słownik z przefiltrowanymi i posortowanymi grupami. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Function} props.handleClick - otwiera okno czatu
 * @param {object} props.unreadChats - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.handleEdit - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.handleDelete - zobacz [setLoadingState]{@link ActionCreators.setLoadingState}
 * @returns {ReactComponent}
 * @see [Components/Chat/withMainPanelList]{@link withMainPanelList}
 */
export const GroupsList = ({
    classes,
    groups,
    handleClick,
    unreadChats,
    handleEdit,
    handleDelete
}) => {
    return Object.values(groups).map((group) => (
        <ListItem
            button
            onClick={(e) => handleClick(e, group)}
            className={classes.nested}
            key={group.id}>
            <ListItemIcon style={{ minWidth: '30px' }}>
                <GroupIcon className={classes.groupIcon} fontSize="medium" />
            </ListItemIcon>
            <Typography className={classes.groupText} noWrap component="div">
                {group.name}
            </Typography>
            <MessageIcon count={getUnreadMessagesCount(unreadChats, group.roomID)} size="small" />
            {isGroupIdTemporary(group.id) ? (
                <Tooltip arrow title="usuń">
                    <IconButton size="small" onClick={(e) => handleDelete(e, group.id)}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip arrow title="szczegóły">
                    <IconButton size="small" onClick={(e) => handleEdit(e, group)}>
                        <SettingsIcon className={classes.settingIcon} />
                    </IconButton>
                </Tooltip>
            )}
        </ListItem>
    ));
};

export default withMainPanelList(GroupsList);
