import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseApiResponse } from 'src/api/types';
import { DEFAULT_SYSTEM_COLOR, SYSTEM_NAMES } from 'src/constants';
import { AppThunk } from 'src/store';
import { SystemConfig, SystemConfigResponse, SystemState, SystemName } from './types';

export const initialState: SystemState = {
    logoSystemu: '',
    logoSystemuMini: '',
    systemName: null,
    systemUrl: null,
    nodeUrl: null,
    color: DEFAULT_SYSTEM_COLOR,
    systemIcon: null,
    initialDataLoaded: false,
    spinnerRun: false,
    assertDataFromApi: false
};

const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setSystemConfig: (state, action: PayloadAction<SystemConfig>) => {
            const {
                systemName,
                systemUrl,
                nodeUrl,
                color,
                systemIcon,
                logoSystemu,
                logoSystemuMini,
                assertDataFromApi
            } = action.payload;
            state.systemName = systemName;
            state.systemUrl = systemUrl;
            state.nodeUrl = nodeUrl;
            state.color = color;
            state.systemIcon = systemIcon;
            state.logoSystemu = logoSystemu;
            state.logoSystemuMini = logoSystemuMini;
            state.assertDataFromApi = assertDataFromApi;
        },
        setInitialDataLoaded: (state) => {
            state.initialDataLoaded = true;
        },
        setSpinner: (state, action: PayloadAction<boolean>) => {
            state.spinnerRun = action.payload;
        },
        closeSpinner: (state) => {
            state.spinnerRun = false;
        }
    }
});

export const handleSystemConfig = (data: SystemConfigResponse) => {
    // validate if system name is detected correctly
    if (!SYSTEM_NAMES.includes(data.systemName)) {
        console.error(`Unvalid system_name from server: ${data.systemName}`);
        return setSystemConfig({ ...data, systemName: 'Mecenas.iT' as SystemName });
    }
    return setSystemConfig(data);
};

/**
 * load system config to system if api response is proper, otherwise throw apropriate error
 */

export const handleSystemConfigApiResponse =
    (apiResponse: BaseApiResponse<SystemConfigResponse>): AppThunk =>
    async (dispatch) => {
        if (apiResponse.data && apiResponse.success) {
            dispatch(handleSystemConfig(apiResponse.data));
        } else {
            throw new Error(apiResponse.message);
        }
    };

export const { setSystemConfig, setInitialDataLoaded, setSpinner, closeSpinner } =
    systemSlice.actions;

export default systemSlice.reducer;
