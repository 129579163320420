import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateSecond from 'src/components/shared/organisms/popupTemplateSecond/PopupTemplateSecond';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography } from '@mui/material';
import { useSearchParam } from 'src/hooks';
import { ApiActionBehavior } from 'src/data/types';
import { TemplateManager } from 'src/utils/src/shared/TemplateManager';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { prepareUrl } from 'src/utils/src/shared/prepareUrl';
import { useUpdateObjectsData } from 'src/hooks';
import { simpleQueryServerData } from 'src/api/src/simpleQueryServerData';
import { setSpinner } from 'src/store/src/general/system/systemSlice';

export const PopupContentConfirmApi: FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const screen = useSearchParam('obiekt');
    const popup = useTypedSelector((state) => state.popup);
    const behaviour = popup.listScreen.action?.behaviour as ApiActionBehavior;
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const updateObjects = useUpdateObjectsData();
    const confirm = async () => {
        const { params, body, url } = new TemplateManager({
            rowData,
            transformationState,
            rowId: popup.listScreen.objectId[0] ? popup.listScreen.objectId[0] : null,
            actionBehavior: behaviour as ApiActionBehavior,
            screen
        }).getData();
        const urlAll = prepareUrl(url, params);
        dispatch(setSpinner(true));
        const resp = await simpleQueryServerData(urlAll, 'POST', body, false);
        if (resp.success) {
            updateObjects({ saveLocationState: false, forceRefreshData: true });
            dispatch(closePopup());
            if (behaviour.data.showResult) {
                new PopupManager({
                    dispatch: dispatch,
                    action: popup.listScreen.action
                }).setPopupInfo(resp.message ? resp.message : 'Wykonano');
                return;
            }
        } else {
            dispatch(closePopup());
            new PopupManager({ dispatch: dispatch, action: popup.listScreen.action }).setPopupInfo(
                resp.message ? resp.message : 'Nie wykonano'
            );

            dispatch(setSpinner(false));
        }
    };
    return (
        <PopupTemplateSecond
            title={globalPopup.contentConfirmation.title}
            buttonLeftTxt={globalPopup.contentConfirmation.button.yes}
            buttonRightTxt={globalPopup.contentConfirmation.button.no}
            buttonRightOnClick={() => dispatch(closePopup())}
            buttonLeftOnClick={() => confirm()}>
            <Typography
                sx={{
                    color: theme.palette.primary.main
                }}>
                {behaviour?.data?.secondConfirmText
                    ? behaviour?.data?.secondConfirmText
                    : behaviour?.data?.confirmText}
            </Typography>
        </PopupTemplateSecond>
    );
};
