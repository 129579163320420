import React from 'react';
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MessageIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { prependChatWindow, closeChatWindow, resetChat } from '../store/actions';
import { getUserOrGroupInfo, getChatByWindowID } from '../store/selectors';
import { emitSocketEvent } from '../sockets';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
    listItem: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    },
    messageIcon: {
        marginRight: spacing(0.4)
    }
}));

/**
 * Bezstanowy komponent funkcyjny podłączony do Redux Store. Wyświetla element listy ukrytych czatów
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {object} props.chatInfo - informacje o użytkowniku lub grupie.
 * @param {string} props.id - id okna czatu.
 * @param {Function} props.closeSelect - funkcja zamykania listy ukrytych czatów
 * @param {Function} props.prependChatWindow - zobacz [prependChatWindow]{@link ActionCreators.prependChatWindow}
 * @param {string} props.className - nazwa klasy css
 * @param {boolean} props.newMessage - informacja czy dany czat ma wiadomości, które mogły zostać nieodczytane.
 * @param {object|null} props.chat - Obiekt czatu. zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {Function} props.closeChatWindow - zobacz [closeChatWindow]{@link ActionCreators.closeChatWindow}
 * @param {Function} props.resetChat - zobacz [resetChat]{@link ActionCreators.resetChat}
 * @returns {ReactComponent}
 * @see  [Components/Chat/Badge]{@link Badge}, [Components/Chat/HiddenChatItem]{@link HiddenChatItem}
 */
export const HiddenChatItem = ({
    chatInfo,
    id,
    closeSelect,
    prependChatWindow,
    className,
    newMessage,
    chat,
    closeChatWindow,
    resetChat
}) => {
    const classes = useStyles();
    /**
     * @memberof HiddenChatItem
     * @method handleChatOpen
     * @description Przenosi kliknięte okno na początek listy otwartych okien i zamyka listę ukrytych czatów.
     * @return {void}
     */
    const handleSelect = (id) => {
        closeSelect();
        prependChatWindow(id);
    };

    /**
     * @memberof HiddenChatItem
     * @method handleWindowClose
     * @description Zamyka okno, resetuje czat. Wysyła informacje do serwera o zamknięciu czatu. Wywołuje [client_close_chat]{@link SocketClientEvents.client_close_chat}.
     * @return {void}
     */
    const handleWindowClose = (event) => {
        event.stopPropagation();
        if (chat) {
            const { chatID, id } = chat;

            resetChat(id);
            emitSocketEvent('close_chat', { chatID });
        }
        closeChatWindow(id);
    };

    return (
        <div>
            <ListItem key={id} button onClick={() => handleSelect(id)} className={classes.listItem}>
                {newMessage && (
                    <MessageIcon fontSize="medium" color="action" className={classes.listItem} />
                )}
                <ListItemText secondary={chatInfo.name} className={className} />
                <IconButton size="small" color="primary" onClick={handleWindowClose}>
                    <CloseIcon />
                </IconButton>
            </ListItem>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    chatInfo: getUserOrGroupInfo(props.id)(state),
    newMessage: getChatByWindowID(props.id)(state)?.newMessage,
    chat: getChatByWindowID(props.id)(state)
});

const mapDispatchToProps = {
    prependChatWindow,
    closeChatWindow,
    resetChat
};

export default connect(mapStateToProps, mapDispatchToProps)(HiddenChatItem);
