import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MoreIcon from '@material-ui/icons/MoreVert';
import Fab from '@material-ui/core/Fab';

import Badge from './Badge';
import HiddenChatItem from '../containers/HiddenChatItem';
import usePrevious from '../../shared/hooks/usePrevious';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        position: 'fixed',
        bottom: 5,
        right: (props) => (props.position + 1) * spacing(36) + 5,
        transition: 'right ease .2s'
    },
    select: {
        position: 'absolute',
        bottom: 60,
        right: 3,
        width: 200,
        backgroundColor: palette.background.default,
        boxShadow: spacing(1),
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: 10,
            width: 10,
            background: palette.background.default,
            bottom: -5,
            left: 120,
            transform: 'rotateZ(45deg)'
        }
    },
    subheader: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        background: palette.grey[300]
    },
    listText: {
        '& .MuiTypography-root': {
            color: palette.secondary.dark,
            width: 118,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    }
}));

/**
 * Stanowy komponent funkcyjny. Wyświetla listę ukrytych okien czatu. Korzysta z hooks [Hooks/usePrevious]{@link userPrevious}
 * Wyświetla komponenty [Components/Chat/Badge]{@link Badge}, [Components/Chat/HiddenChatItem]{@link HiddenChatItem}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {number} props.position - liczba na podstawie której pozycja komponentu zostanie wygenerowana.
 * @param {string[]} props.hiddenWindows - tablica id okien do ukrycia
 * @param {Function} props.setHiddenWindows - funkcja ustawiania ukrytych okien
 * @param {string[]} props.windowsNewMsg - informacja o oknach czatu z wiadomościami które mogły zostać nieodczytane.
 * @property {boolean} selectOpen - stan otwarcia listy ukrytych czatów
 * @property {Function} setSelectOpen - funkcja ustawiania stanu otwarcia listy ukrytych czatów
 * @property {number} newMsgLength - ilość ukrytych czatów z które mogą mieć niesprawdzone nowe wiadmości
 * @returns {ReactComponent}
 * @see  [Components/Chat/Badge]{@link Badge}, [Components/Chat/HiddenChatItem]{@link HiddenChatItem}
 */
const HiddenChatsPanel = ({ position, hiddenWindows, setHiddenWindows, windowsNewMsg }) => {
    const classes = useStyles({ position });

    const [selectOpen, setSelectOpen] = useState(false);
    const prevWindows = usePrevious(hiddenWindows);

    const newMsgLength = windowsNewMsg.filter((w) => hiddenWindows.indexOf(w) !== -1).length;

    /**
     * @memberof HiddenChatsPanel
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Odpowiada za zmiane okien do ukrycia kiedy zmieniają się propsy.
     */
    useEffect(() => {
        if (hiddenWindows && prevWindows) {
            if (
                hiddenWindows.length !== prevWindows.length ||
                hiddenWindows.some((w) => prevWindows.indexOf(w) === -1)
            ) {
                setHiddenWindows(hiddenWindows);
            }
        }
    }, [hiddenWindows, prevWindows, setHiddenWindows]);

    const closeSelect = () => setSelectOpen(false);

    return (
        <Grow unmountOnExit in={Boolean(hiddenWindows.length)}>
            <div className={classes.root}>
                <Fab color="primary" size="large" onClick={() => setSelectOpen((open) => !open)}>
                    <MoreIcon />
                    <Badge size="large" count={newMsgLength} inset />
                </Fab>
                <Grow unmountOnExit in={selectOpen} timeout={200}>
                    <List
                        subheader={
                            <ListSubheader className={classes.subheader}>Czaty</ListSubheader>
                        }
                        className={classes.select}>
                        {hiddenWindows.map((id) => (
                            <HiddenChatItem
                                key={id}
                                id={id}
                                closeSelect={closeSelect}
                                className={classes.listText}
                            />
                        ))}
                    </List>
                </Grow>
            </div>
        </Grow>
    );
};

export default HiddenChatsPanel;
