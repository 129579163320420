import React from 'react';
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';

import { setSearchQuery } from '../store/actions';

/**
 * Bezstanowy komponent funkcyjny podłączony do reduxStore. Odpowiada za wyświetlanie pola wyszukiwania grup lub użytkowników
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - argument zwracanej funkcji. Propsy przekazane do komponentu
 * @param {Function} props.handleChange - zobacz [setSearchQuery]{@link ActionCreators.setSearchQuery}
 * @param {string} props.value - searchGroupsQuery lub searchUsersQuery - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {'groups'|'users'} props.type - typ wyszukiwanych obiektów
 * @param {boolean} props.searchOpen - searchGroups lub searchUsers - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @returns {ReactComponent}
 */
export const SearchField = ({ handleChange, value, type, searchOpen, classes }) => {
    const placeholder = `Szukaj ${type === 'groups' ? ' grup' : 'użytkowników'}`;

    return (
        <Collapse in={searchOpen} unmountOnExit>
            <ListItem className={classes.searchItem}>
                <input
                    id={`chat_main_panel_${type}_search`}
                    className={classes.searchInput}
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(type, e.target.value)}
                    placeholder={placeholder}
                />
            </ListItem>
        </Collapse>
    );
};

const mapStateToProps = (state, props) => {
    const { app } = state;
    const value = props.type === 'groups' ? app.searchGroupsQuery : app.searchUsersQuery;
    const searchOpen = props.type === 'groups' ? app.searchGroups : app.searchUsers;

    return { value, searchOpen };
};

const mapDispatchToProps = {
    handleChange: setSearchQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
