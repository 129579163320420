import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store/index';
import { setPopup } from 'src/store/src/popup';
import { PopupTypesWew } from 'src/store/src/popup/popup/types';

export const useCheckIsPopupToDisplay = () => {
    const dispatch = useAppDispatch();
    const alertsData = useTypedSelector((state) => state.alertsData);
    useEffect(() => {
        if (alertsData.alerts.length) {
            dispatch(
                setPopup({
                    type: 'alert',
                    typeWew: alertsData.alerts[0]?.type as PopupTypesWew,
                    isPossibleClose: alertsData.alerts[0]?.type === 'changePassword' ? false : true
                })
            );
        }
    }, [alertsData, dispatch]);
};
