import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Zoom } from '@mui/material';

const useStyles = makeStyles(({ palette, shadows }) => ({
    paper: {
        minWidth: 300,
        width: 400,
        maxWidth: '98%',
        color: palette.text.secondary,
        backgroundColor: palette.background.paper,
        border: '1px solid rgba(66,66,66, 0.4)',
        boxShadow: shadows[2]
    },
    bold: {
        fontSize: 22,
        fontWeight: 'bold',
        color: palette.text.primary
    },
    error: {
        fontWeight: 'bold',
        fontSize: 22,
        color: palette.primary.light
    },
    btn: {
        color: palette.info.main
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} unmountOnExit {...props} />;
});

/**
 * Bezstanowy komponent funkcyjny. Wyswietla popup informujący o błędzie w aplikacji.
 * @component
 * @category Components
 * @subcategory Shared
 * @param {object} props - Propsy komponentu
 * @param {Object} props.error - stan błędu aplikacji
 * @param {string} props.error.type - wiadomość o typie błędu
 * @param {string} props.error.message - tekst błędu
 * @param {funtion} props.cancel - akcja anulowania błędu.
 * @returns {ReactComponent}
 */
const ErrorModal = ({ error, handleError }) => {
    const classes = useStyles();

    const open = !!error;

    return (
        <Dialog
            className={classes.root}
            TransitionComponent={Transition}
            open={open}
            onClose={handleError}
            closeAfterTransition
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}>
            <DialogTitle>
                <span className={classes.bold}>Ups, błąd! </span>
                <span className={classes.error}>{error && error.type}</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{error && error.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleError} size="large" className={classes.btn} color="default">
                    anuluj
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorModal;
