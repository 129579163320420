import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
    content: {
        background: palette.secondary.dark,
        color: palette.text.white
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie powiadomień.
 * @component
 * @category Components
 * @subcategory Shared
 * @param {object} props - Propsy komponentu
 * @param {Function} props.handleClose - Akcja wywoływana przy zamknięciu powiadomienia
 * @param {string} props.message - Wiadomośc do wyświetlenia.
 * @param {boolean} props.open - Wyznacza czy komponent jest wyświetlany.
 * @returns {ReactComponent}
 */
const Notification = ({ handleClose, message, open }) => {
    const classes = useStyles();
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            ContentProps={{
                classes: { root: classes.content }
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
                <IconButton size="small" aria-label="close" color="primary" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    );
};

export default Notification;
