import { useEffect, useRef } from 'react';

/**
 * @category Hooks
 * @subcategory Shared
 * @function usePrevious
 * @description Hook zwracający poprzednią wartoś przekazanej zmiennej
 * @param {any} val - zmienna której poprzednią wartość zwróci funkcja.
 * @returns {any}
 */
const usePrevious = (val) => {
    const ref = useRef();

    // This will happen only after return
    useEffect(() => {
        ref.current = val;
    }, [val]);
    // return happens first
    return ref.current;
};

export default usePrevious;
