import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(({ palette, shadows }) => ({
    paper: {
        minWidth: 300,
        width: 400,
        minHeight: 430,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        maxWidth: '98%',
        color: palette.text.secondary,
        backgroundColor: palette.background.default,
        boxShadow: shadows[1]
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} unmountOnExit {...props} />;
});

/**
 * Bezstanowy komponent funkcyjny. Wyświetla okno dodawania/edycji grupy, renderuje swoje dzieci.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Function} props.handleClose - funkcja zamykająca oknos.
 * @param {ReactComponent[]|ReactComponent} props.children - komponenty dzieci.
 * @param {boolean} props.open - informacja czy wyświetlić okno
 * @returns {ReactComponent}
 */
const CreateGroupModal = ({ handleClose, children, open }) => {
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}>
            {children}
        </Dialog>
    );
};

export default CreateGroupModal;
