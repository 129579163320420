import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import GroupsList from './GroupsList';
import UsersList from './UsersList';
import EditGroup from './EditGroup';
import useStorage from '../../shared/hooks/useStorage';
import CreateGroup from '../containers/CreateGroup';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        boxSizing: 'border-box',
        height: spacing(35),
        overflowY: 'scroll',
        width: '100%',
        padding: 0,
        paddingTop: spacing(0.5)
    },
    item: {
        width: '100%',
        minHeight: spacing(6.4),
        transition: 'height 0.3s ease'
    },
    item_1: {
        background: palette.secondary.light,
        flexGrow: (props) => (props.groupsOpen ? 1 : 0)
    },
    item_2: {
        background: palette.info.light,
        flexGrow: (props) => (props.usersOpen ? 1 : 0)
    },
    listItem: {
        background: palette.background.paper,
        paddingLeft: spacing(1.6),
        paddingBottom: spacing(0.8),
        paddingTop: spacing(0.8)
    },
    listItemIcon: {
        '&.MuiListItemIcon-root': {
            minWidth: spacing(5.6)
        }
    },
    dangerIcon: {
        color: palette.primary.light
    },
    infoIcon: {
        color: palette.info.dark
    },
    secondaryIcon: {
        color: palette.secondary.dark
    }
}));

/**
 * Stanowy komponent funkcyjny. Wyświetla treść głównego okna czatu. Korzysta z [Hooks/useStorage]{@link useStorage}
 * Wyświetla komponenty  [Components/Chat/GroupsList]{@link GroupsList}, [Components/Chat/UsersList]{@link UsersList}, [Components/Chat/EditGroup]{@link EditGroup}, [Components/Shared/CreateGroup]{@link CreateGroup}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Object.<string,object>} props.groups - słownik z przefiltrowanymi i posortowanymi grupami. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Object.<string,object[]>} props.users - słownik z typami użytkowników jako klucze i przefiltrowanymi i posortowanymi użytkownikami jako wartości. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {object} props.unreadChats - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.toggleSearch - zobacz [toggleSearch]{@link ActionCreators.toggleSearch}
 * @param {string} props.searchUsers - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.searchGroups - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {object|null} props.self - obiekt z informacjami o użytkowniku
 * @param {string} props.self.name - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.self.activeStatus - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.self.id - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {string} props.self.roomID - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {Function} props.setCallback - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.setLoadingState - zobacz [setLoadingState]{@link ActionCreators.setLoadingState}
 * @param {Function} props.setChatWindowLoading - zobacz [setChatWindowLoading]{@link ActionCreators.setChatWindowLoading}
 * @param {Function} props.deleteGroup - zobacz [deleteGroup]{@link ActionCreators.deleteGroup}
 * @property {boolean} groupsOpen - stan rozwinięcia listy z grupami. Zwracana z [Hooks/useStorage]{@link useStorage}
 * @property {Function} setGroupsOpen - Funkcja ustawiania groupsOpen. Zwracana z [Hooks/useStorage]{@link useStorage}
 * @property {boolean} usersOpen - stan rozwinięcia listy z użytkownikami. Zwracana z [Hooks/useStorage]{@link useStorage}
 * @property {Function} setUsersOpen - Funkcja ustawiania usersOpen. Zwracana z [Hooks/useStorage]{@link useStorage}
 * @property {boolean} newGroupOpen - stan otwarcia komponentu CreateGroup
 * @property {Function} setNewGroupOpen - Funkcja ustawiania newGroupOpen.
 * @property {boolean} editGroup - stan otwarcia komponentu EditGroup
 * @property {Function} setEditGroup - Funkcja ustawiania editGroup.
 * @property {string[]} groupNames - Tablica nazw dostępnych użytkownikowi grup.
 * @returns {ReactComponent}
 * @see [Components/Chat/GroupsList]{@link GroupsList}, [Components/Chat/UsersList]{@link UsersList}, [Components/Chat/EditGroup]{@link EditGroup}, [Components/Chat/CreateGroup]{@link CreateGroup}
 */
const MainPanel = ({
    groups,
    users,
    handleOpenWindow,
    unreadChats,
    toggleSearch,
    searchUsers,
    searchGroups,
    self,
    setCallback,
    setLoadingState,
    setChatWindowLoading,
    deleteGroup
}) => {
    const [groupsOpen, setGroupsOpen] = useStorage('panel_open_1', true);
    const [usersOpen, setUsersOpen] = useStorage('panel_open_2', true);
    const [newGroupOpen, setNewGroupOpen] = useState(false);
    const [editGroup, setEditGroup] = useState(null);

    const classes = useStyles({ groupsOpen, usersOpen });

    const groupNames = Object.values(groups)
        .map((g) => g.name)
        .filter((n) => n !== editGroup?.name);

    /**
     * @memberof MainPanel
     * @method toggleGroupsSearch
     * @description Ustawia tryb wyszukiwania grup i rozwija panel z grupami.
     * @return {void}
     */
    const toggleGroupsSearch = () => {
        toggleSearch('groups');
        !searchGroups && setGroupsOpen(true);
    };

    /**
     * @memberof MainPanel
     * @method toggleUsersSearch
     * @description Ustawia tryb wyszukiwania grup i rozwija panel z użytkownikami.
     * @return {void}
     */
    const toggleUsersSearch = () => {
        toggleSearch('users');
        !searchUsers && setUsersOpen(true);
    };

    /**
     * @memberof MainPanel
     * @method handleEditGroup
     * @description Otwiera dialog edycji grupy.
     * @return {void}
     */
    const handleEditGroup = (event, group) => {
        event.stopPropagation();
        setEditGroup(group);
    };

    /**
     * @memberof MainPanel
     * @method handleDeleteGroup
     * @description Wywołuje akcję usunięcia grupy.
     * @return {void}
     */
    const handleDeleteGroup = (event, id) => {
        event.stopPropagation();
        deleteGroup(id);
    };

    return (
        <List className={classes.root}>
            <ListItem
                className={classes.listItem}
                button
                onClick={() => setGroupsOpen(!groupsOpen)}
                selected={groupsOpen}>
                <ListItemIcon className={classes.listItemIcon}>
                    <GroupIcon fontSize="large" className={classes.dangerIcon} />
                </ListItemIcon>
                <ListItemText primary="Grupy" />
                <ListItemSecondaryAction>
                    <Tooltip arrow TransitionComponent={Zoom} title="wyszukaj grupę">
                        <IconButton edge="end" size="small" onClick={toggleGroupsSearch}>
                            {searchGroups ? (
                                <CloseIcon className={classes.dangerIcon} />
                            ) : (
                                <SearchIcon className={classes.secondaryIcon} />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Tooltip arrow TransitionComponent={Zoom} title="dodaj grupę">
                        <IconButton edge="end" onClick={() => setNewGroupOpen(true)}>
                            <AddIcon className={classes.infoIcon} />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <GroupsList
                unreadChats={unreadChats}
                maxHeight={243}
                open={groupsOpen}
                groups={groups}
                handleClick={handleOpenWindow}
                type="groups"
                searchOpen={true}
                handleEdit={handleEditGroup}
                handleDelete={handleDeleteGroup}
            />

            <ListItem
                className={classes.listItem}
                button
                onClick={() => setUsersOpen(!usersOpen)}
                selected={usersOpen}>
                <ListItemIcon className={classes.listItemIcon}>
                    <PersonIcon fontSize="large" className={classes.dangerIcon} />
                </ListItemIcon>
                <ListItemText primary="Użytkownicy" />

                <ListItemSecondaryAction>
                    <Tooltip arrow TransitionComponent={Zoom} title="wyszukaj grupę">
                        <IconButton edge="end" size="small" onClick={toggleUsersSearch}>
                            {searchUsers ? (
                                <CloseIcon className={classes.dangerIcon} />
                            ) : (
                                <SearchIcon className={classes.secondaryIcon} />
                            )}
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>

            <UsersList
                unreadChats={unreadChats}
                maxHeight={243}
                open={usersOpen}
                users={users}
                handleClick={handleOpenWindow}
                type="users"
                searchOpen={true}
            />

            <CreateGroup
                open={newGroupOpen}
                openGroupList={() => setGroupsOpen(true)}
                handleClose={() => setNewGroupOpen(false)}
            />
            <EditGroup
                groupNames={groupNames}
                open={!!editGroup}
                handleClose={() => setEditGroup(null)}
                users={users}
                group={editGroup}
                self={self}
                setCallback={setCallback}
                setLoadingState={setLoadingState}
                setChatWindowLoading={setChatWindowLoading}
            />
        </List>
    );
};

export default MainPanel;
