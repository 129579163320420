import React, { useState, useRef, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FileIcon from '@material-ui/icons/AttachFile';
import EmojiIcon from '@material-ui/icons/EmojiEmotions';
import SendIcon from '@material-ui/icons/Send';

import IconBtn from '../components/IconBtn';
import EmojiPickerWrapper from '../components/EmojiPickerWrapper';
import { emitSocketEvent } from '../sockets';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        boxSizing: 'border-box',
        position: 'relative',
        borderTop: '1px solid ' + palette.divider,
        width: '100%',
        flexGrow: 0,
        background: palette.background.default,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0.4)
    },
    message: {
        boxSizing: 'border-box',
        width: '90%',
        fontSize: spacing(1.4),
        height: spacing(7),
        borderRadius: 4
    },
    textArea: {
        boxSizing: 'border-box',
        fontSize: spacing(1.4),
        width: '100%',
        fontFamily: '"Lato", sans-serif',
        resize: 'none',
        backgroundColor: palette.background.default,
        color: palette.text.primary,
        caretColor: palette.text.secondary,
        padding: `${spacing(0.8)}px ${spacing(1.2)}px`,
        border: 'none !important',
        borderRadius: 4,
        overflowX: 'hidden',
        margin: 0,
        '&:focus': {
            outline: 'none !important',
            border: '1px solid ' + palette.secondary.light + ' !important',
            boxShadow: 'none !important'
        }
    },
    buttons: {
        height: spacing(7.5),
        width: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

/**
 * Stanowy komponent funkcyjny. Wyświetla panel wpisywania wiadomości.
 * Wyświetla komponenty [Components/Chat/IconBtn]{@link IconBtn}, [Components/Chat/EmojiPickerWrapper]{@link EmojiPickerWrapper}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {string} props.id - id okna czatu
 * @param {boolean} props.active - informacja czy okno czatu jest aktywne
 * @param {object} props.chatID - Id czatu. zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {string} props.activeWindow - id aktywnego oktna. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.handleFile - funkcja obsługująca wysyłanie pliku
 * @param {Function} props.createChat - Funkcja wysyłająca żądanie stworzenia czatu na serwer
 * @param {Function} props.appLoading - stan ładowania aplikacji. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {boolean} props.loading - stan ładowania okna czatu
 * @param {number} props.windowOrder - numer okna od prawej strony
 * @param {Function} props.scrollToBottom - Funkcja odpowiada za przeskrolowanie okna na dół po wysłaniu wiadomości
 * @property {string} message - stan treści wpisywanej wiadomości.
 * @property {Function} setMessage - Funkcja ustawiania message.
 * @property {boolean} pickerOpen -  stan otwarcia pop-upa z emoji
 * @property {Funtion} setPickerOpen - funckja ustawiania pickerOpen
 * @property {boolean} isChat - zmienna pomocnicza, sprawdza czy czat jest pustym obiektem, co oznacza, że czat nie został stworzony na serwerze.
 * @returns {ReactComponent}
 * @see [Components/Chat/IconBtn]{@link IconBtn}, [Components/Chat/EmojiPickerWrapper]{@link EmojiPickerWrapper}
 */
export const MessagePanel = ({
    id,
    active,
    chatID,
    handleFile,
    createChat,
    loading,
    appLoading,
    roomID,
    minimized,
    scrollToBottom
}) => {
    const classes = useStyles();

    const [message, setMessage] = useState('');
    const [pickerOpen, setPickerOpen] = useState(false);

    const textRef = useRef();
    const fileRef = useRef();

    /**
     * @memberof MessagePanel
     * @method createChat
     * @description Funkcja obsługuje wysłanie wiadmości na serwer. Wywołuje [client_text_message]{@link SocketClientEvents.client_text_message} i [client_create_chat]{@link SocketClientEvents.client_create_chat}
     * @return {void}
     */
    const handleSubmit = useCallback(() => {
        if (loading || appLoading) {
            return;
        }

        if (chatID) {
            const data = {
                message: { content: message, type: 'text' },
                chatID
            };

            if (message) {
                emitSocketEvent('text_message', data);
            }
        } else {
            const successAction = (chatID) => {
                const data = {
                    message: { content: message, type: 'text' },
                    chatID
                };
                emitSocketEvent('text_message', data);
            };

            createChat(successAction);
        }
        scrollToBottom();
        setMessage('');
    }, [message, chatID, createChat, loading, appLoading, scrollToBottom]);

    /**
     * @memberof MessagePanel
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook obsługuje wysyłanie wiadmości za pomocą klawisza enter.
     */
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (
                document.activeElement.id === textRef.current.id &&
                e.key === 'Enter' &&
                !e.shiftKey
            ) {
                handleSubmit();
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleSubmit]);

    /**
     * @memberof MessagePanel
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook obsługuje focus na tekście wiadomości po zamknięciu pickera.
     */
    useEffect(() => {
        if (active && !pickerOpen) {
            textRef.current.focus();
            textRef.current.selectionStart = textRef.current.selectionEnd =
                textRef.current.value.length;
        }
    }, [active, pickerOpen]);

    /**
     * @memberof MessagePanel
     * @method openPicker
     * @description Funkcja otwiera emojii picker
     * @return {void}
     */
    const openPicker = (e) => {
        e.stopPropagation();
        !pickerOpen && setPickerOpen(true);
    };

    /**
     * @memberof MessagePanel
     * @method closePicker
     * @description Funkcja zamyka emojii picker
     * @return {void}
     */
    const closePicker = useCallback(() => {
        setPickerOpen(false);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.message}>
                <textarea
                    id={'chat_message_text_' + id}
                    className={classes.textArea}
                    ref={textRef}
                    rows="3"
                    disabled={minimized}
                    spellCheck="false"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Wpisz wiadomość"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <div className={classes.buttons}>
                <IconBtn
                    small
                    color="secondary"
                    tone="main"
                    title="wybierz emoji"
                    clickHandler={openPicker}
                    active={true}
                    icon={(className) => <EmojiIcon className={className} />}
                />
                <input
                    ref={fileRef}
                    onChange={(e) => handleFile(e.target.files[0], roomID)}
                    type="file"
                    hidden
                />
                <IconBtn
                    small
                    color="primary"
                    tone="light"
                    title="dodaj plik"
                    clickHandler={() => fileRef.current.click()}
                    active={!loading && chatID && !appLoading}
                    disabled={loading || !chatID || appLoading}
                    icon={(className) => <FileIcon className={className} />}
                />
                <IconBtn
                    small
                    color="primary"
                    tone="light"
                    title="wyślij wiadomość"
                    clickHandler={handleSubmit}
                    active={!loading && !appLoading}
                    disabled={loading || appLoading}
                    icon={(className) => <SendIcon className={className} />}
                />
            </div>
            <EmojiPickerWrapper
                setMessage={setMessage}
                handleClose={closePicker}
                open={pickerOpen}
            />
        </div>
    );
};

export default React.memo(MessagePanel);
