import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Zoom } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';

import useHandleClose from '../../shared/hooks/useHandleClose';
import Avatar from './Avatar';

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
    root: {
        position: 'absolute',
        maxWidth: 300,
        maxHeight: 200,
        overflowY: 'auto',
        zIndex: 5,
        top: (props) => (props.top ? props.top : 'auto'),
        right: (props) => (props.right ? props.right : 'auto'),
        bottom: (props) => (props.bottom ? props.bottom : 'auto'),
        left: (props) => (props.left ? props.left : 'auto'),
        width: 300,
        backgroundColor: palette.background.default,
        boxShadow: shadows[1],
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: 10,
            width: 10,
            background: palette.background.default,
            top: -5,
            left: 120,
            transform: 'rotateZ(45deg)'
        }
    },
    listHeader: {
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    chip: {
        display: 'inline-block',
        margin: spacing(1),
        background: palette.grey[500],
        color: palette.text.white,
        borderRadius: 6,
        textTransform: 'uppercase',
        fontFamily: '"Lato", sans-serif',
        fontSize: 14,
        padding: '6px 12px'
    },
    listText: {
        maxWidth: 230,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        color: palette.text.secondary,
        fontWeight: 700
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie listy możliwych do dodania użytkowników. Komponent używa hooka [useHandleClose]{@link useHandleClose}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.open - flaga czy komponent ma być wyświetlany
 * @param {Function} props.toggleOpen - funkcja ustawiania flagi open
 * @param {Object.<string,object>} users - Słownik użytkowników gdzie kluczami są typy użytkowników a wartościami tablice z użytkownikami
 * @param {Function} props.toggleOpen - funkcja ustawiania flagi open
 * @param {Object} props.positon - pozycja listy
 * @param {Function} [props.positon.left]
 * @param {Function} [props.positon.right]
 * @param {Function} [props.positon.top]
 * @param {Function} [props.positon.bottom]
 * @property {boolean} hasLength - flaga potrzebna do otwarcia komponentu (nie otworzy się jeśli false)
 * @returns {ReactComponent}
 */
const AddUser = ({ open, toggleOpen, addUser, users, position }) => {
    const classes = useStyles(position);
    useHandleClose(open, toggleOpen, 'chat_addUser_dropdown');

    /**
     * @memberof AddUser
     * @method handleClick
     * @description Dodaje użytkownika i zamyka listę
     * @param {Event} event - Event
     * @param {object} user - obiekt user.
     * @returns {void}
     */
    const handleClick = (event, user) => {
        event.stopPropagation();
        addUser(user);
        toggleOpen();
    };

    const hasLength = !!Object.keys(users).length;
    return (
        <Zoom unmountOnExit in={open && hasLength} timeout={200}>
            <List
                subheader={
                    <ListSubheader className={classes.listHeader}>Dodaj użytkownika</ListSubheader>
                }
                className={classes.root}
                id="chat_addUser_dropdown">
                {Object.keys(users).map((key) => (
                    <div className={classes.container} key={key}>
                        <span className={classes.chip}>
                            {key === 'paneledostepowe' ? 'pozostali' : key}
                        </span>
                        {users[key].map((user) => (
                            <ListItem
                                button
                                onClick={(e) => handleClick(e, user)}
                                key={user.id}
                                className={classes.nested}>
                                <ListItemAvatar>
                                    <Avatar avatar={user.avatar} />
                                </ListItemAvatar>
                                <Typography className={classes.listText} noWrap component="div">
                                    {user.name}
                                </Typography>
                            </ListItem>
                        ))}
                    </div>
                ))}
            </List>
        </Zoom>
    );
};

export default AddUser;
