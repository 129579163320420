import React from 'react';

import Grow from '@material-ui/core/Grow';

import EmojiPicker from './EmojiPicker';

/**
 * Bezstanowy komponent funkcyjny. Opakowuje EmojiPicker.
 * Wyświetla komponenty [Components/Chat/EmojiPicker]{@link EmojiPicker}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Function} setMessage - Funkcja ustawia treść wiadomości.
 * @param {Function} props.handleClose - funkcja zamyka EmojiPicker
 * @param {boolean} props.open - informacja EmojiPicker jest otwarty
 * @returns {ReactComponent}
 * @see [Components/Chat/EmojiPicker]{@link EmojiPicker}
 */
const EmojiPickerComp = ({ setMessage, handleClose, open }) => {
    return (
        <Grow timeout={200} in={open} unmountOnExit>
            <EmojiPicker setMessage={setMessage} handleClose={handleClose} />
        </Grow>
    );
};

export default EmojiPickerComp;
