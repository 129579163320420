import React, { useEffect } from 'react';
import { connect, batch } from 'react-redux';
import MainWindow from './MainWindow';
import OpenChatBtn from '../components/openButton/OpenButton';
import ChatWindowsList from '../components/ChatWindowsList';
import ErrorModal from '../../shared/components/ErrorModal';
import AcceptDialog from '../../shared/components/AcceptDialog';
import Notification from '../../shared/components/Notification';
import ErrorUI from '../components/ErrorUI';
import ErrorBoundry from '../../shared/components/ErrorBoundry';
import {
    cancelError,
    resetAppState,
    setChatOpen,
    setCallback,
    executeCallback,
    setHiddenWindows,
    setActiveChatWindow,
    removeNotification,
    setConfig
} from '../store/actions';
import { executeWindowCallback, cancelWindowCallback } from '../../shared/utils/index';
import { emitSocketEvent, initSocketEvents } from '../sockets';
import { getUnreadChatsCount, getWindowsWithNewMsgs } from '../store/selectors';
import ContainerBtn from '../components/ContainerBtn';

/**
 * Bezstanowy komponent funkcyjny podłączony do Redux Strore. Punkt wejściowy do aplikacji czatu. Łączy się z backendem i przekazuje propsy pozostałym komponentom.
 * Wyświetla komponenty  [Components/Chat/OpenChatBtn]{@link OpenChatBtn}, [Components/Chat/MainWindow]{@link MainWindow}, [Components/Chat/ChatWindowsList]{@link ChatWindowsList}, [Components/Shared/ErrorModal]{@link ErrorModal}, [Components/Shared/AcceptDialog]{@link AcceptDialog}, [Components/Shared/Notification]{@link Notification}, [Components/Chat/ErrorUI]{@link ErrorUI}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {object} props.config - obiekt z informacjami o użytkowniku i aplikacji
 * @param {string} props.config.userID - id użytkownika
 * @param {string} props.config.userType - typ użytkownika
 * @param {string} props.config.system_url - url systemu
 * @param {string} props.config.node_url - url serwera nodejs
 * @param {string} props.config.sessionID - id sesji
 * @param {string[]} props.openWindows - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.resetAppState - zobacz [resetAppState]{@link ActionCreators.resetAppState}
 * @param {object} props.error - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.cancelError - zobacz [cancelError]{@link ActionCreators.cancelError}
 * @param {"mobile" | "medium" | "large" | "xlarge"} props.viewport string z wielkością dostępnego viewportu
 * @param {Function} props.setChatOpen - zobacz [setChatOpen]{@link ActionCreators.setChatOpen}
 * @param {boolean} props.chatOpen - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {number} props.unread - liczba czatów z nieodczytanymi wiadomościami.
 * @param {boolean} props.disconnected - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.cb - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.node_url - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.system_url - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.executeCallback -zobacz [executeCallback]{@link ActionCreators.executeCallback}
 * @param {Function} props.setCallback -zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.setHiddenWindows -zobacz [setHiddenWindows]{@link ActionCreators.setHiddenWindows}
 * @param {string[]} props.windowsNewMsg - tablica z otartymi oknami czatu w których nowe wiadomości mogły zostać przeoczone.
 * @param {string[]} props.activeChats - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.setActiveChatWindow -zobacz [setActiveChatWindow]{@link ActionCreators.setActiveChatWindow}
 * @param {string} props.notification - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.removeNotification -zobacz [removeNotification]{@link ActionCreators.removeNotification}
 * @param {Function} props.setConfig -zobacz [setConfig]{@link ActionCreators.setConfig}
 * @returns {ReactComponent}
 * @see [Components/Chat/OpenChatBtn]{@link OpenChatBtn}, [Components/Chat/MainWindow]{@link MainWindow}, [Components/Chat/ChatWindowsList]{@link ChatWindowsList}, [Components/Chat/ErrorModal]{@link ErrorModal}, [Components/Chat/AcceptDialog]{@link AcceptDialog}, [Components/Chat/Notification]{@link Notification}, [Components/Chat/ErrorUI]{@link ErrorUI}
 */
export const Chat = ({
    config,
    openWindows,
    resetAppState,
    error,
    cancelError,
    viewport,
    setChatOpen,
    chatOpen,
    unread,
    disconnected,
    cb,
    node_url,
    system_url,
    executeCallback,
    setCallback,
    setHiddenWindows,
    windowsNewMsg,
    activeChats,
    setActiveChatWindow,
    notification,
    removeNotification,
    setConfig
}) => {
    const { userID, userType } = config;
    /**
     * @memberof Chat
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook ustawia configurację otrzymaną z backendu jako stan aplikacji.
     */
    useEffect(() => {
        setConfig(config);
    }, []);

    /**
     * @memberof Chat
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook inicjuje połączenie z serwerem websockets i wywołuje pierwszy event. Wywołuje [initSocketEvents]{@link initSocketEvents} i [client_synch]{@link SocketClientEvents.client_synch}.
     */
    useEffect(() => {
        if (node_url && system_url && userID && userType) {
            initSocketEvents(node_url, system_url, userID, userType);
        }
    }, [node_url, system_url, userID, userType]);

    /**
     * @memberof Chat
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook ustawia listener na dokumencie, który anuluje aktywne okno czatu po kliknięciu poza którymkowiek z okien
     */
    useEffect(() => {
        /* Jesli nie kliknąłeś w okno czatu */
        document.addEventListener(
            'click',
            (e) => {
                if (!e.target.closest('.chat_chat_window')) {
                    setActiveChatWindow(null);
                }
            },
            true
        );
    }, [setActiveChatWindow]);

    /**
     * @memberof Chat
     * @method handleChatOpen
     * @description Otwiera i zamyka czat. Gdy zamyka, resetuje stan aplikacji i wysyła na serwer żądanie zakończenia wszystkich otwartych czatów.
     * Wywołuje[client_close_chat]{@link SocketClientEvents.client_close_chat} lub [client_open_app]{@link SocketClientEvents.client_open_app}.
     * @return {void}
     */
    const handleChatOpen = () => {
        if (chatOpen) {
            activeChats.forEach((el) => {
                /* change something like string 'private7' to number 7 */
                el = +el.match(/\d+$/)[0];
                emitSocketEvent('close_chat', { chatID: el });
            });
            batch(() => {
                resetAppState();
                setChatOpen(false);
            });
            return;
        }

        emitSocketEvent('open_app');
        setChatOpen(true);
    };

    /**
     * @memberof Chat
     * @method closeNotification
     * @description zamyka aktualnie wyświetlaną notyfikacje.
     * @return {void}
     */
    const closeNotification = () => removeNotification(notification);

    /**
     * @memberof Chat
     * @method handleDialogAccept
     * @description Wykonuje callbacki oczekujące na potwierdzenie użytkownika
     * @return {void}
     */
    const handleDialogAccept = () => {
        executeWindowCallback();
        executeCallback();
    };

    /**
     * @memberof Chat
     * @method handleDialogReject
     * @description Anuluje callbacki oczekujące na potwierdzenie użytkownika
     * @return {void}
     */
    const handleDialogReject = () => {
        cancelWindowCallback();
        setCallback(null);
    };

    return (
        <ErrorBoundry render={() => <ErrorUI />}>
            <OpenChatBtn handleOpen={handleChatOpen} unread={unread} disconnected={disconnected} />
            <ContainerBtn order={3} open={chatOpen} type="todo"></ContainerBtn>
            {chatOpen && <MainWindow handleChatOpen={handleChatOpen} />}

            <ChatWindowsList
                openWindows={openWindows}
                viewport={viewport}
                setHiddenWindows={setHiddenWindows}
                windowsNewMsg={windowsNewMsg}
            />

            <AcceptDialog
                open={!!cb}
                handleAccept={handleDialogAccept}
                handleReject={handleDialogReject}
            />

            <Notification
                message={notification}
                open={!!notification}
                handleClose={closeNotification}
            />
        </ErrorBoundry>
    );
};

const mapStateToProps = (state) => {
    const { app, system } = state;
    const { openWindows, error, chatOpen, disconnected, cb, activeChats, notification } = app;

    const { nodeUrl: node_url, systemUrl: system_url } = system;

    return {
        openWindows,
        error,
        chatOpen,
        disconnected,
        cb,
        unread: getUnreadChatsCount(state),
        windowsNewMsg: getWindowsWithNewMsgs(state),
        activeChats,
        notification,
        node_url,
        system_url
    };
};

const mapDispatchToProps = {
    cancelError,
    resetAppState,
    setChatOpen,
    executeCallback,
    setCallback,
    setHiddenWindows,
    setActiveChatWindow,
    removeNotification,
    setConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
