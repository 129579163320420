import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

import useHandleClose from '../../shared/hooks/useHandleClose';

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
    root: {
        position: 'absolute',
        zIndex: 5,
        top: spacing(6.1),
        right: spacing(0.5),
        width: 200,
        backgroundColor: palette.grey[200],
        boxShadow: shadows[1],
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: spacing(1),
            width: spacing(1),
            background: palette.grey[200],
            top: -spacing(0.5),
            right: spacing(7),
            transform: 'rotateZ(45deg)'
        }
    },
    listIcon: {
        minWidth: spacing(3)
    },
    icon: {
        color: palette.primary.light
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie listy opcji. Komponent używa hooka [useHandleClose]{@link useHandleClose} <br />
 * Wywołuje [client_config_activeStatus]{@link SocketClientEvents.client_config_activeStatus}, [client_config_sounds]{@link SocketClientEvents.client_config_sounds}, [client_config_busy]{@link SocketClientEvents.client_config_busy}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {boolean} props.open - flaga czy komponent ma być wyświetlany
 * @param {Function} props.toggleOpen - funkcja ustawiania flagi open
 * @param {Function} props.setSounds - funkcja ustawiania opcji dźwięków
 * @param {boolean} props.sounds - wartość opcji dźwięków
 * @param {Function} props.setActiveStatus - funkcja ustawiania opcji statusu
 * @param {boolean} props.activeStatus - wartość opcji statusu
 * @param {boolean} props.busy - wartość opcji zajętości
 * @param {Function} props.setBusy - funkcja ustawiania opcji zajętości
 * @returns {ReactComponent}
 */
const Settings = ({
    open,
    toggleOpen,
    setSounds,
    setActiveStatus,
    sounds,
    activeStatus,
    busy,
    setBusy
}) => {
    const classes = useStyles();
    useHandleClose(open, toggleOpen, 'chat_settings_dropdown');

    return (
        <Zoom unmountOnExit in={open} timeout={200}>
            <List
                subheader={<ListSubheader>Opcje</ListSubheader>}
                className={classes.root}
                id="chat_settings_dropdown">
                <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                        {activeStatus ? (
                            <VisibilityIcon className={classes.icon} />
                        ) : (
                            <VisibilityOffIcon className={classes.icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary="Widoczność" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" onClick={setActiveStatus} checked={activeStatus} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                        {sounds ? (
                            <VolumeUpIcon className={classes.icon} />
                        ) : (
                            <VolumeOffIcon className={classes.icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary="Dźwięki" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" onClick={setSounds} checked={sounds} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.listIcon}>
                        {busy ? (
                            <NotificationsOffIcon className={classes.icon} />
                        ) : (
                            <NotificationsIcon className={classes.icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary="Zajęty" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" onClick={setBusy} checked={busy} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </Zoom>
    );
};

export default Settings;
