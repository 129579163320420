import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';

import IconBtn from './IconBtn';

const useStyles = makeStyles(({ palette, spacing }) => ({
    header: {
        position: 'relative',
        background: palette.grey[200],
        width: spacing(35),
        height: spacing(5.5),
        padding: spacing(0.4),
        margin: 0,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px 4px 0 0',
        boxSizing: 'border-box'
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla header okna czatu.
 * Wyświetla komponent [Componnents/Chat/IconBtn]{@link IconBtn}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {ReactComponent[]|ReactComponent} props.children - komponenty dzieci.
 * @param {Function} props.handleWindowClose - funkcja zamykająca okno
 * @param {boolean} props.active - informacja czy dane okno jest sfokusowane
 * @param {Function} props.toggleMinimize - funkcja do minimalizowania maxymalizowania okna
 * @param {boolean} props.minimized - informacja czy dane okno jest zminimalizowane
 * @returns {ReactComponent}
 * @see [Componnents/Chat/IconBtn]{@link IconBtn}
 */
const WindowHeader = ({ children, handleWindowClose, active, toggleMinimize, minimized }) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            {children}
            <IconBtn
                title={minimized ? 'otwórz okno' : 'miminimalizuj'}
                color="secondary"
                tone="main"
                active={active}
                clickHandler={toggleMinimize}
                icon={(className) =>
                    minimized ? (
                        <MaximizeIcon className={className} />
                    ) : (
                        <MinimizeIcon className={className} />
                    )
                }
            />
            <IconBtn
                color="primary"
                tone="light"
                title="zamknij czat"
                active={active}
                clickHandler={handleWindowClose}
                icon={(className) => <CloseIcon className={className} />}
            />
        </div>
    );
};

export default WindowHeader;
