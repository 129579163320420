import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddUserIcon from '@material-ui/icons/PersonAdd';

import CreateGroupModal from '../components/CreateGroupModal';
import AddUser from '../components/AddUser';
import SelectedUsersList from '../components/SelectedUsersList';
import { usersArrayToObject, usersToArray, createClassNamesString } from '../../shared/utils';
import { createGroup } from '../store/actions';
import { getUsersObject } from '../store/selectors';

const useStyles = makeStyles(({ palette }) => ({
    header: {
        padding: 23,
        flexGrow: 0,
        borderBottom: '2px solid ' + palette.grey[200],
        color: palette.primary.main,
        '& h2': {
            margin: 0,
            fontSize: 24,
            textTransform: 'uppercase',
            fontWeight: 400,
            fontFamily: '"Lato", sans-serif'
        }
    },
    content: {
        padding: 12,
        flexGrow: 1
    },
    footer: {
        borderTop: '1px solid ' + palette.grey[200],
        padding: 12,
        flexGrow: 0,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    list: {
        width: '100%'
    },
    button: {
        boxShadow: 'none',
        margin: '0 8px',
        color: palette.common.white,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    green: {
        background: palette.info.main,
        '&:hover': {
            background: palette.info.dark
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
        padding: 8,
        minHeight: 31
    },
    topRow: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-end'
    },
    error: {
        color: palette.primary.light,
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: '"Lato", sans-serif'
    },
    input: {
        '& input': {
            border: 'none !important',
            backgroundColor: 'transparent !important',
            '&:focus': {
                border: 'none !important',
                boxShadow: 'none !important',
                outline: 'none'
            }
        },
        '& .MuiInputLabel-root': {
            fontSize: 18
            // color: palette.secondary.main
        }
    }
}));

/**
 * Stanowy komponent funkcyjny podłączony do Redux Store. Wyświetla panel tworzenia nowej grupy.
 * Wyświetla komponenty  [Components/Chat/CreateGroupModal]{@link CreateGroupModal}, [Components/Chat/AddUser]{@link AddUser}, [Components/Chat/SelectedUsersList]{@link SelectedUsersList}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Object.<string,object[]>} props.users - słownik z typami użytkowników jako klucze i niefiltrowanymi użytkownikami jako wartości. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Function} props.createGroup - zobacz [createGroup]{@link ActionCreators.createGroup}
 * @param {boolean} props.open - wyznacza czy panel jest otwarty
 * @param {Function} props.handleClose - funkcja zamyka panel.
 * @param {Function} props.openGroupList - funkcja rozwija listę z grupami w komponencie MainPanel.
 * @property {object[]} filteredUsers - stan listy użytkowników odfiltrowanych przez tych dodanych do grupy
 * @property {Function} setFilteredUsers - Funkcja ustawiania filteredUsers.
 * @property {object[]} selectedUsers - stan listy użytkowników  dodanych do grupy.
 * @property {Function} setSelectedUsers - Funkcja ustawiania selectedUsers.
 * @property {string} name - stan nazwy grupy
 * @property {Function} setName - Funkcja ustawiania name.
 * @property {boolean} isValid - stan poprawności nazwy grupy.
 * @property {Function} setIsValid - Funkcja ustawiania isValid.
 * @property {boolean} addUserOpen - stan otwarcia listy AddUser.
 * @property {Function} setAddUserOpen - Funkcja ustawiania addUserOpen.
 * @returns {ReactComponent}
 * @see [Components/Chat/CreateGroupModal]{@link CreateGroupModal}, [Components/Chat/AddUser]{@link AddUser}, [Components/Chat/EditGroup]{@link SelectedUsersList}, [Components/Chat/SelectedUsersList]{@link SelectedUsersList}
 */
export const CreateGroup = ({ users, createGroup, open, handleClose, openGroupList }) => {
    const classes = useStyles();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [name, setName] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [addUserOpen, setAddUserOpen] = useState(false);

    /**
     * @memberof CreateGroup
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Czyści stan komponentu kiedy jest zamykany.
     */
    useEffect(() => {
        if (!open) {
            setFilteredUsers([]);
            setSelectedUsers([]);
            setAddUserOpen(false);
            setName('');
        }
    }, [open]);

    /**
     * @memberof CreateGroup
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Ustawia filtered users za każdym razem gdy zmienia sie selectedUsers.
     */
    useEffect(() => {
        function filterUsers(users) {
            // create flat array from users object, then filter if not in selected users, then recreate users object
            const types = Object.keys(users);
            const flatten = usersToArray(users);
            const filtered = flatten.filter(
                (user) => selectedUsers.findIndex((sUser) => sUser.id === user.id) === -1
            );

            return usersArrayToObject(filtered, types);
        }
        if (selectedUsers.length) {
            setFilteredUsers(filterUsers(users));
        } else {
            setFilteredUsers(users);
        }
    }, [selectedUsers, users]);

    /**
     * @memberof CreateGroup
     * @method checkValidity
     * @description Sprawdza poprawność wybranej nazwy.
     * @return {void}
     */
    const checkValidity = (name) => name.length < 16 && name.length > 2;

    /**
     * @memberof CreateGroup
     * @method handleName
     * @description Obsługuje zmianę nazwy grupy.
     * @return {void}
     */
    const handleName = (event) => {
        const name = event.target.value;
        if (!checkValidity(name)) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
        setName(name);
    };

    /**
     * @memberof CreateGroup
     * @method addUser
     * @description Dodaje użytkownika do selectedUsers.
     * @return {void}
     */
    const addUser = (user) => setSelectedUsers((prev) => [...prev, user]);

    /**
     * @memberof CreateGroup
     * @method removeUser
     * @description Usuwa użytkownika z selectedUsers.
     * @return {void}
     */
    const removeUser = (id) => setSelectedUsers((prev) => prev.filter((user) => user.id !== id));

    /**
     * @memberof CreateGroup
     * @method removeUser
     * @description Tworzy grupę, rozwija panel z grupami i zamyka panel tworzenia grupy.
     * @return {void}
     */
    const handleCreateGroup = (e) => {
        e.stopPropagation();
        createGroup(name, selectedUsers);
        openGroupList();
        handleClose();
    };

    /**
     * @memberof CreateGroup
     * @method handleAddUserOpen
     * @description Otwiera listę z użytkownikami, jeśli są jacyś do dodania.
     * @return {void}
     */
    const handleAddUserOpen = (event) => {
        if (Object.keys(filteredUsers).length) {
            // event propagate at bubbling phrase to window after AddUser subscribe to window click
            // by useHandleCLose
            // other solution for that problem: setTimeout(() => setAddUserOpen(true), 10);
            event.stopPropagation();
            setAddUserOpen(true);
        }
    };

    return (
        <CreateGroupModal open={open} handleClose={handleClose}>
            <div className={classes.header}>
                <h2>Dodaj nową grupę</h2>
            </div>
            <div className={classes.content}>
                <div className={classes.form}>
                    <div className={createClassNamesString(classes.row, classes.topRow)}>
                        <TextField
                            className={classes.input}
                            value={name}
                            onChange={handleName}
                            color="secondary"
                            label="nazwa grupy"
                        />

                        <Button
                            variant="contained"
                            size="small"
                            className={classes.button}
                            onClick={handleAddUserOpen}
                            color="secondary"
                            startIcon={<AddUserIcon />}>
                            dodaj osoby
                        </Button>
                        <AddUser
                            position={{ top: 51, right: 5 }}
                            toggleOpen={() => setAddUserOpen(false)}
                            open={addUserOpen}
                            users={filteredUsers}
                            addUser={addUser}
                        />
                    </div>
                    <div className={classes.row}>
                        {name && !isValid ? (
                            <p className={classes.error}>Nazwa musi zawierać od 3 do 15 liter.</p>
                        ) : null}
                    </div>
                </div>
                <div className={classes.list}>
                    <SelectedUsersList handleClick={removeUser} users={selectedUsers} />
                </div>
            </div>
            <div className={classes.footer}>
                <Button
                    size="large"
                    className={[classes.button, classes.green].join(' ')}
                    disabled={!(isValid && selectedUsers.length)}
                    onClick={handleCreateGroup}
                    variant="contained">
                    dodaj
                </Button>
                <Button
                    size="large"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}>
                    anuluj
                </Button>
            </div>
        </CreateGroupModal>
    );
};

const mapStateToProps = (state) => ({
    users: getUsersObject(state)
});
const mapDispatchToProps = {
    createGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
