import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(({ palette, spacing }) => ({
    icon: {
        color: (props) => (props.active ? palette[props.color][props.tone] : palette.grey[400]),
        width: (props) => (props.small ? spacing(2) : spacing(2.4)),
        height: (props) => (props.small ? spacing(2) : spacing(2.4))
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Przycisk z ikoną.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Function} props.icon - prop renderujący. Zwraca przekazaną do komponentu ikone.
 * @param {Function} props.clickHandler - funkcja obsługująca kliknięcie przycisku.
 * @param {string} props.title - informacja wyświetlana w Tooltipie
 * @param {boolean} props.disabled - informacja czy przycisk ma być nieaktywny
 * @param {boolean} props.small - informacja czy przycisk ma być mały, wykorzystywany w css komponentu
 * @param {boolean} props.active - informacja czy przycisk ma być stylowany jako aktywny, wykorzystywany w css komponentu
 * @returns {ReactComponent}
 */
const IconBtn = (props) => {
    const { icon, clickHandler, title, disabled } = props;
    const classes = useStyles(props);

    return (
        <Tooltip arrow TransitionComponent={Zoom} title={title}>
            <span>
                <IconButton disabled={disabled} onClick={clickHandler} size="small">
                    {icon(classes.icon)}
                </IconButton>
            </span>
        </Tooltip>
    );
};
IconBtn.displayName = 'IconButton';

export default IconBtn;
