import React, { Dispatch, FC, SetStateAction } from 'react';
import { Divider } from '@mui/material';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';
import { useTypedSelector, useAppDispatch } from 'src/store';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { TypesOfContentPopOver } from 'src/components/menu/organisms/userSection/UserSection';
import { useLogout } from 'src/hooks';
import { topMenu } from 'src/constants/subtitles';
import { ubdateModule } from 'src/store/src/menu';
import { useTheme } from '@mui/material/styles';
import styles from './UserList.module.css';

type Props = {
    setContentPopOver: Dispatch<SetStateAction<TypesOfContentPopOver>>;
    handleClose: () => void;
};

const UserList: FC<Props> = ({ setContentPopOver, handleClose }) => {
    const user = useTypedSelector((state) => state.user);
    const menuStructureData = useTypedSelector((state) => state.menu.menuStructureData);
    const params = new URLSearchParams(`obiekt=${user.userType}&id=${user.userID}`);
    const dispatchRedux = useAppDispatch();
    const logout = useLogout();
    const theme = useTheme();

    const setMenuState = () => {
        MenuStateManager.ubdateMenuModuleData({
            menuStructureData,
            dispatch: dispatchRedux,
            ubdateModule,
            screenSelected: user.userType,
            moduleSelected: null
        });
    };
    const run = () => {
        handleClose();
        setMenuState();
    };

    return (
        <div
            className={styles.wrapperMain}
            style={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}>
            <div className={styles.wrapperInner}>
                <LegacyCompatibleLink url={'ekran_obiektu.php'} queryParams={params} outerFn={run}>
                    <p className={styles.p}>{user.userNameToDisplay}</p>
                </LegacyCompatibleLink>
            </div>
            {menuStructureData.userOptions.findIndex((e) => e.type === 'Mój raport') !== -1 && (
                <div className={styles.wrapperInner} onClick={() => setContentPopOver('MY_REPORT')}>
                    <p className={styles.p}>{topMenu.options.userSection.two}</p>
                </div>
            )}
            <div className={styles.wrapperDivider}>
                <Divider />
            </div>
            <div className={styles.wrapperInner}>
                <p className={styles.p} onClick={logout}>
                    {topMenu.options.userSection.last}
                </p>
            </div>
        </div>
    );
};

export default UserList;
