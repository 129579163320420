import { AlertsResponse, AlertTemplate } from 'src/store/src/general/alerts/types';
import { BaseApiResponse } from 'src/api/types';

/**
 * Fetches information about actual system configuration
 */

const alertsData: { alerts: AlertTemplate[] } = {
    alerts: [
        // {
        //     type: '2fa',
        //     link: {
        //         url: '/ekran_kodu_do_2fa.php',
        //         params: ''
        //     },
        //     popup: {
        //         title: 'Konieczne zeskanowanie kodu QR'
        //     }
        // }
    ]
};

export async function getAlerts(): Promise<BaseApiResponse<AlertsResponse>> {
    // Default options are marked with *
    // const response = await fetch('/api_react/alerts.php', {
    //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'include', // include, *same-origin, omit
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // });

    // const dataPrimary = await response.text();

    // if (dataPrimary === 'Unauthorized access!') {
    //     return {
    //         data: null,
    //         errorCode: 1,
    //         success: false,
    //         message: 'Unauthorized access!'
    //     };
    // }

    // const dataParsed = JSON.parse(dataPrimary);
    // try {
    //     return { data: dataParsed, errorCode: null, success: true, message: 'Zdobyto dane' };
    // } catch (e) {
    //     return { data: null, errorCode: 500, success: false, message: dataParsed };
    // }

    return {
        message: 'Zdobyto dane',
        success: true,
        errorCode: null,
        data: alertsData
        // data: data_WITH_SECOND_LEVEL
    };
}
