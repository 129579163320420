import React from 'react';
import AvatarComponent from '@material-ui/core/Avatar';

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie kółka z awatarem przy imieniu i nazwisku użytkownika.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {string} props.className - nazwa klasy przekazywana do css
 * @param {object} props.avatar - obiekt avatara
 * @param {string} props.avatar.initials - iniciały użytkownika
 * @param {string} props.avatar.color - color HEX, avatara przekazywany do css
 * @returns {ReactComponent}
 */
const Avatar = ({ avatar, className }) => {
    return (
        <AvatarComponent
            className={className}
            style={{ backgroundColor: avatar.color, color: '#f3f3f3' }}>
            {avatar.initials}
        </AvatarComponent>
    );
};

export default Avatar;
