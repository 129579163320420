import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store/index';
import { loadAlert } from 'src/store/src/shared/actions';
import { useNavigate } from 'react-router-dom';
import { combineFullUrl } from 'src/utils';

export const useLoadAlerts = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const alerts = useTypedSelector((state) => state.alertsData.alerts);

    useEffect(() => {
        if (alerts.length) {
            navigate(
                combineFullUrl(alerts[0]?.link.url, new URLSearchParams(alerts[0]?.link.params))
            );
        }
        dispatch(loadAlert());
    }, [alerts, dispatch, navigate]);
};
