import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import SettingsIcon from '@material-ui/icons/Settings';
import Grow from '@material-ui/core/Grow';

import Window from '../components/Window';
import WindowHeader from '../components/WindowHeader';
import IconBtn from '../components/IconBtn';
import HeaderTitle from '../components/HeaderTitle';
import MainPanel from '../components/MainPanel';
import Spinner from '../../shared/components/Spinner';
import Settings from '../components/Settings';
import {
    openChatWindow,
    toggleSearch,
    setCallback,
    setLoadingState,
    setChatWindowLoading,
    deleteGroup
} from '../store/actions';
import { getFilteredUsersObject, getFilteredGroups, getSelf } from '../store/selectors';
import mainWindowID from '../constants/mainWindowID';
import useWindowState from '../../shared/hooks/useWindowState';
import { emitSocketEvent } from '../sockets';

/**
 * Stanowy komponent funkcyjny podłączony do Redux Strore. Wyświetla główne okno czatu. Używa [Hooks/useWindowsState]{@link useWindowsState}
 * Wyświetla komponenty  [Components/Chat/Window]{@link Window}, [Components/Chat/WindowHeader]{@link WindowHeader}, [Components/Chat/HeaderTitle]{@link HeaderTitle}, [Components/Chat/Settings]{@link Settings}, [Components/Chat/IconBtn]{@link IconBtn}, [Components/Chat/MainPanel]{@link MainPanel}, [Components/Shared/Spinner]{@link Spinner} <br />
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Function} props.handleOpen - funkcja do zamykania otwierania czatu
 * @param {Object.<string,object>} props.groups - słownik z przefiltrowanymi i posortowanymi grupami. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Object.<string,object[]>} props.users - słownik z typami użytkowników jako klucze i przefiltrowanymi i posortowanymi użytkownikami jako wartości. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {object|null} props.self - obiekt z informacjami o użytkowniku
 * @param {string} props.self.name - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.self.activeStatus - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.self.id - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {string} props.self.roomID - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {Function} props.openChatWindow - zobacz [openChatWindow]{@link ActionCreators.openChatWindow}
 * @param {object} props.unreadChats - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {boolean} props.sounds - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {object} props.chats - zobacz [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {boolean} props.sounds - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {boolean} props.loading - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {boolean} props.busy - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {boolean} props.sounds - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.toggleSearch - zobacz [toggleSearch]{@link ActionCreators.toggleSearch}
 * @param {string} props.searchUsers - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.searchGroups - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.setCallback - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.setLoadingState - zobacz [setLoadingState]{@link ActionCreators.setLoadingState}
 * @param {Function} props.setChatWindowLoading - zobacz [setChatWindowLoading]{@link ActionCreators.setChatWindowLoading}
 * @param {Function} props.deleteGroup - zobacz [deleteGroup]{@link ActionCreators.deleteGroup}
 * @property {boolean} settingsOpen -  stan otwarcia komponentu opcji
 * @property {Funtion} setSettingsOpen - funckja ustawiania stanu otwarcia komponentu opcji
 * @property {boolean} minimized - stan zminimalizowania okna. Zwracana z [Hooks/useWindowsState]{@link useWindowsState}
 * @property {Function} toggleMinimize - Funkcja ustawiania minimized. Zwracana z [Hooks/useWindowsState]{@link useWindowsState}
 * @returns {ReactComponent}
 * @see [Components/Chat/Window]{@link Window}, [Components/Chat/WindowHeader]{@link WindowHeader}, [Components/Chat/HeaderTitle]{@link HeaderTitle}, [Components/Chat/Settings]{@link Settings}, [Components/Chat/IconBtn]{@link IconBtn}, [Components/Chat/MainPanel]{@link MainPanel}, [Components/Shared/Spinner]{@link Spinner}
 */
export const MainWindow = ({
    handleChatOpen,
    groups,
    users,
    self,
    openChatWindow,
    unreadChats,
    sounds,
    chats,
    loading,
    busy,
    toggleSearch,
    searchUsers,
    searchGroups,
    setCallback,
    setLoadingState,
    setChatWindowLoading,
    deleteGroup
}) => {
    const [settingsOpen, setSettingsOpen] = useState(false);

    const [minimized, toggleMinimize] = useWindowState(mainWindowID);

    /**
     * @memberof MainWindow
     * @method toggleSettings
     * @description Otwiera i zamyka komponent opcji.
     * @return {void}
     */
    const toggleSettings = useCallback(
        (event) => {
            // event propagate at bubbling phrase to window after Settings subscribe to window click
            // by useHandleCLose, other solution is to give little timeout on setSettingsOpen
            event.stopPropagation();
            setSettingsOpen((prev) => !prev);
        },
        [setSettingsOpen]
    );

    /**
     * @memberof MainWindow
     * @method handleOpenWindow
     * @description Otwiera okno czatu, obsługuje żądanie otwarcia czatu na serwerze. Wywołuje [client_open_chat]{@link SocketClientEvents.client_open_chat}.
     * @return {void}
     */
    const handleOpenWindow = useCallback(
        (event, obj) => {
            event.stopPropagation();

            if (obj.roomID) {
                if (!chats[obj.roomID]) {
                    emitSocketEvent('open_chat', { roomID: obj.roomID, windowID: obj.id });
                }
            }
            openChatWindow(obj.id);
        },
        [openChatWindow, chats]
    );

    return (
        <Window minimized={minimized} windowOpen={true} handleFocus={() => {}} windowOrder={0}>
            <WindowHeader
                minimized={minimized}
                toggleMinimize={toggleMinimize}
                handleWindowClose={handleChatOpen}
                active={true}>
                <HeaderTitle activeStatus={self?.activeStatus} active={true}>
                    {self?.name}
                </HeaderTitle>
                <Settings
                    open={settingsOpen}
                    toggleOpen={toggleSettings}
                    setSounds={() => emitSocketEvent('config_sounds')}
                    setActiveStatus={() => emitSocketEvent('config_activeStatus')}
                    setBusy={() => emitSocketEvent('config_busy')}
                    activeStatus={self?.activeStatus}
                    sounds={sounds}
                    busy={busy}
                />
                <IconBtn
                    title="opcje"
                    color="secondary"
                    tone="main"
                    disabled={loading}
                    active={true}
                    clickHandler={toggleSettings}
                    icon={(className) => <SettingsIcon className={className} />}
                />
            </WindowHeader>
            <Grow in={!minimized} timeout={200} unmountOnExit>
                <div style={{ position: 'relative', flexGrow: 1 }}>
                    <MainPanel
                        handleOpenWindow={handleOpenWindow}
                        unreadChats={unreadChats}
                        groups={groups}
                        users={users}
                        toggleSearch={toggleSearch}
                        searchUsers={searchUsers}
                        searchGroups={searchGroups}
                        self={self}
                        setCallback={setCallback}
                        setLoadingState={setLoadingState}
                        setChatWindowLoading={setChatWindowLoading}
                        deleteGroup={deleteGroup}
                    />
                    {loading ? <Spinner /> : null}
                </div>
            </Grow>
        </Window>
    );
};

const mapStateToProps = (state) => {
    const { app, user, chats } = state;
    const { sounds, busy, loading, unreadChats, searchUsers, searchGroups } = app;
    const name = user.userNameToDisplay;

    return {
        loading,
        name,
        sounds,
        busy,
        groups: getFilteredGroups(state),
        users: getFilteredUsersObject(state),
        unreadChats,
        searchUsers,
        searchGroups,
        chats,
        self: getSelf(state)
    };
};

const mapDispatchToProps = {
    openChatWindow,
    toggleSearch,
    setCallback,
    setLoadingState,
    setChatWindowLoading,
    deleteGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(MainWindow);
