import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import useHandleClose from '../../shared/hooks/useHandleClose';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        zIndex: 50005,
        position: 'absolute',
        transform: () => (spacing(1) === 10 ? 'scale(1)' : 'scale(.8)'),
        bottom: () => (spacing(1) === 10 ? 82 : 34),
        right: () => (spacing(1) === 10 ? 2 : -30),
        '& .emoji-mart-search': {
            display: 'none'
        }
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla pop-up z emojii. Używa [Hooks/useHandleClose]{@link useHandleClose}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Function} setMessage - Funkcja ustawia treść wiadomości.
 * @param {Function} props.handleClose - funkcja zamyka EmojiPicker
 * @returns {ReactComponent}
 */
const EmojiPickerComp = ({ setMessage, handleClose }) => {
    const classes = useStyles();

    useHandleClose(true, handleClose, 'chat_emojiPicker_popup');

    return (
        <div className={classes.root} id="chat_emojiPicker_popup">
            <Picker
                onSelect={(em) => setMessage((msg) => (msg += em.native))}
                native={true}
                title="Wybierz Emoji"
                showSkinTones={false}
                showPreview={false}
                skin={1}
                emojiSize={20}
                sheetSize={32}
            />
        </div>
    );
};

export default EmojiPickerComp;
