import React, { FC } from 'react';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { globalPopup } from 'src/constants/subtitles';
import { PopupContentTypesWew } from 'src/components/shared/organisms/popupContentTypesWew/PopupContentTypesWew';

export const PopupContentAlert: FC = () => {
    return (
        <PopupTemplateThird title={globalPopup.contentAlert.title}>
            <PopupContentTypesWew />
        </PopupTemplateThird>
    );
};
