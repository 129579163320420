import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import Avatar from './Avatar';
import { formatMessageDate, parseMessage, createUrl } from '../../shared/utils';
import { emitSocketEvent } from '../sockets';
import MessageRead from '../containers/MessageRead';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        boxSizing: 'border-box',
        paddingTop: (props) => (props.sameUser ? spacing(0.5) : spacing(1.5)),
        position: 'relative',
        width: '100%',
        fontSize: spacing(1.4),
        fontFamily: '"Lato", sans-serif',
        color: palette.text.secondary,
        '& p': {
            margin: 0
        },
        '& a': {
            fontWeight: 700,
            color: palette.info.dark,
            textDecoration: 'none',
            display: 'block'
        }
    },
    container: {
        display: 'flex',
        justifyContent: (props) =>
            props.type === 'notification' ? 'center' : props.self ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start'
    },
    bubble: {
        padding: spacing(1),
        wordWrap: 'break-word',
        position: 'relative',
        marginRight: spacing(1),
        marginBottom: (props) => (props.lastRead ? spacing(0.8) : undefined),
        borderRadius: spacing(1),
        width: 'fit-content',
        background: (props) =>
            props.type === 'notification'
                ? palette.grey[500]
                : props.self
                ? palette.grey[200]
                : palette.secondary.light,
        '& *': {
            userSelect: 'text'
        }
    },
    date: {
        fontSize: spacing(1.2),
        width: '100%',
        color: palette.grey[400],
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: spacing(1)
    },
    avatar: {
        width: spacing(3.5),
        height: spacing(3.5),
        fontSize: '1rem',
        marginRight: spacing(0.4),
        opacity: (props) => (!props.sameUser || props.dateNeeded ? 1 : 0)
    },
    image: {
        width: '100%',
        height: 'auto',
        pointerEvents: 'none'
    },
    message: {
        maxWidth: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: (props) => (props.self ? 'flex-end' : 'flex-start'),
        '&:hover button': {
            opacity: 1,
            pointerEvents: 'initial'
        },
        '& a': {
            cursor: 'pointer'
        }
    },
    deleted: {
        fontSize: spacing(1),
        fontStyle: 'italic',
        color: palette.primary.main
    },
    delBtn: {
        padding: spacing(0.3),
        display: 'flex',
        margin: (props) => (props.self ? `0 ${spacing(0.2)}px 0 0` : `0 0 0 ${spacing(0.2)}px`),
        color: palette.primary.light,
        transition: 'opacity .4s ease',
        opacity: 0,
        pointerEvents: 'none'
    },
    notification: {
        fontSize: spacing(1.3),
        color: palette.background.paper,
        whiteSpace: 'nowrap',
        fontWeight: 700
    }
}));

/**
 * Bezstanowy komponent funkcyjny podłączony do Redux Strore. Odpowiada za wyświetlanie pojedyńczej wiadomości.
 * Wyświetla komponenty  [Components/Chat/Avatar]{@link Avatar}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {object|null} props.self - jeśli wiadomość należy do aktualnego użytkownika - obiekt z informacjami o nim - jeśli nie - null
 * @param {string} props.self.name - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.self.activeStatus - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.self.id - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {string} props.self.roomID - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {object} props.message - wiadomość. Zobacz  [Redux/Store/Message]{@link Store.Message}
 * @param {object|undefined} props.user - Jeśli wiadomość nie należy do aktualnego użytkownika, informacje o nadawcy wiadomości, jeśli należy, undefined  Zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {object|undefined} props.prevMsg - Poprzednia wiadomość jeśli istnieje. Zobacz  [Redux/Store/Message]{@link Store.Message}
 * @param {Function} props.scrollToPosition - funkcja umożliwiająca scrollowanie okna z wiadomościami.
 * @param {number} props.chatID - identyfikator czatu w bazie danych
 * @param {Function} props.setCallback - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {string} props.node_url url serwera nodejs. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.system_url url systemu. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {object | undefined} props.lastRead Obiekt z informacjami którzy użytkownicy przeczytali tę wiadomość jak o ostatnią. Zobacz w [Redux/Store/AppSlice]{@link Store.ChatSlice}
 * @property {boolean} sameUser - informacja czy poprzednia wiadomość należała do tego samego użytkownika.
 * @property {boolean} dateNeeded - informacja o tym czy należy podać datę nad wiadomością.
 * @returns {ReactComponent}
 * @see [Components/Chat/Avatar]{@link Avatar}
 */
const Message = ({
    self,
    message,
    user,
    prevMsg,
    scrollToPosition,
    chatID,
    setCallback,
    node_url,
    system_url,
    lastRead
}) => {
    const sameUser = prevMsg ? message.roomID === prevMsg.roomID : false;
    const dateNeeded = prevMsg ? message.created_at - prevMsg.created_at > 60 * 5 : true;
    const type = message.type;
    const classes = useStyles({ self, sameUser, dateNeeded, type, lastRead });
    const deleted = type === 'deleted';
    let content;

    switch (type) {
        case 'image':
            content = (
                <a href={null} onClick={(e) => handleLink(e, message)}>
                    <img
                        className={classes.image}
                        src={createUrl(
                            node_url,
                            '/download/',
                            `system_url=${system_url}`,
                            `fileName=${message.fileName}`
                        )}
                        onLoad={scrollToPosition}
                        alt={message.content}
                        height={500}
                    />
                </a>
            );
            break;

        case 'file':
            content = (
                <a href={null} onClick={(e) => handleLink(e, message)}>
                    {message.content}
                </a>
            );
            break;

        case 'deleted':
            content = <p className={classes.deleted}>{message.content}</p>;
            break;
        case 'notification':
            content = <p className={classes.notification}>{message.content}</p>;
            break;
        default:
            content = parseMessage(message.content);
    }

    /**
     * @memberof Message
     * @method handleLink
     * @param {Event} e - event
     * @param {object} msg - wiadomość typu plik lub zdjęcie. Zobacz  [Redux/Store/Message]{@link Store.Message}
     * @description Funkcja obsługuje pobieranie plików i zdjęć.
     * @return {void}
     */
    const handleLink = (e, msg) => {
        e.preventDefault();
        const form = document.createElement('form');
        const fileNameInput = document.createElement('input');
        const nameInput = document.createElement('input');
        const systemIdInput = document.createElement('input');

        fileNameInput.name = 'fileName';
        fileNameInput.value = msg.fileName;
        nameInput.name = 'name';
        nameInput.value = msg.content;
        systemIdInput.name = 'system_url';
        systemIdInput.value = system_url;
        form.action = createUrl(node_url, 'download');
        form.method = 'post';
        form.target = '_blank';
        form.hidden = 'true';

        form.append(fileNameInput);
        form.append(nameInput);
        form.append(systemIdInput);
        document.body.append(form);
        form.submit();
        form.remove();
    };

    /**
     * @memberof Message
     * @method handleDelete
     * @description Funkcja wysyła na serwer żądanie usunięcia wiadomośći. Wywołuje [client_delete_message]{@link SocketClientEvents.client_delete_message}.
     * @return {void}
     */
    const handleDelete = () => {
        setCallback(() =>
            emitSocketEvent('delete_message', {
                chatID,
                id: message.id
            })
        );
    };

    const delBtn = (
        <Tooltip arrow title="usuń wiadomość">
            <IconButton size="small" className={classes.delBtn} onClick={handleDelete}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );

    return (
        <div className={classes.root}>
            {dateNeeded && (
                <div className={classes.date}>{formatMessageDate(message.created_at * 1000)}</div>
            )}
            <div className={classes.container}>
                {user ? <Avatar avatar={user.avatar} className={classes.avatar} /> : null}
                <div className={classes.message}>
                    {self && !deleted && delBtn}
                    <div className={classes.bubble}>
                        {content}
                        {lastRead && <MessageRead lastRead={lastRead} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Message);
