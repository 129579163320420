import React from 'react';
import { getUserTimeLogout } from 'src/api/src/shared/getUserTimeLogout';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParam } from 'src/hooks';
import { ubdateTimeSession } from 'src/api/src/shared/ubdateTimeSession';
import { Typography } from '@material-ui/core';
import { useLogout } from 'src/hooks';
import { useTypedSelector } from 'src/store';
import { globalPopup } from 'src/constants/subtitles';

declare global {
    interface Window {
        idTimeout: NodeJS.Timeout;
    }
}

export const CheckTime = () => {
    const theme = useTheme();
    const php = useSearchParam('php_url');
    const obiekt = useSearchParam('obiekt');
    const { timeToLogout, isLogged } = useTypedSelector((state) => state.user);
    const refTimer = React.useRef<HTMLDivElement>(null);
    const refPopup = React.useRef<HTMLSpanElement>(null);
    const logout = useLogout();

    const ubdateTimer = React.useCallback(
        (time: number) => {
            refTimer.current!.innerText = String(time);
            const interval = setInterval(() => {
                const actualTimer = Number(refTimer.current!.innerText);
                if (actualTimer === 1) {
                    refPopup.current!.style.visibility = 'hidden';
                    logout();
                }
                if (actualTimer) {
                    refTimer.current!.innerText = String(actualTimer - 1);
                } else {
                    clearInterval(interval);
                }
            }, 1000);
        },
        [logout]
    );

    const check = React.useCallback(
        async (initTime?: number) => {
            let time = initTime;
            if (!initTime) {
                console.info('CheckTime: getUserTimeLogout');
                const res = await getUserTimeLogout();
                if (res.data === null || res.data === 'null') return;
                time = res.data;
            }
            if (time) {
                if (time < 61) {
                    refPopup.current!.style.visibility = 'visible';
                    ubdateTimer(time);
                } else {
                    const timeSettimeout = (time - 60) * 1000;
                    if (timeSettimeout) {
                        const data = setTimeout(function () {
                            check();
                        }, timeSettimeout);
                        window.idTimeout = data;
                    }
                }
            } else {
                logout();
            }
        },
        [logout, php, ubdateTimer]
    );
    const handleUpdateTimeSession = async () => {
        const res = await ubdateTimeSession();
        refPopup.current!.style.visibility = 'hidden';
        check(res.data);
    };
    React.useEffect(() => {
        if (isLogged) {
            if (window.idTimeout) {
                clearTimeout(window.idTimeout);
            }
            if (timeToLogout) {
                const time = Number(timeToLogout) * 60;
                const timeSettimeout = time - 60 * 1000;
                const data = setTimeout(function () {
                    check(time);
                }, timeSettimeout);
                window.idTimeout = data;
            }
        }
    }, [timeToLogout, obiekt, isLogged]);

    React.useEffect(() => {
        const focus = async () => {
            if (isLogged) {
                const timeBefore = localStorage.getItem('GT');
                if (!timeBefore || Number(timeBefore) < new Date().getTime()) {
                    const res = await getUserTimeLogout();
                    if (res?.data > 123) {
                        const time = new Date().getTime() + (res.data - 122) * 1000;
                        localStorage.setItem('GT', String(time));
                    } else {
                        if (!res?.success) {
                            refPopup.current!.style.visibility = 'hidden';
                            logout();
                        } else {
                            refTimer.current!.innerText = String(res.data - 2);
                        }
                    }
                }
            } else {
                refPopup.current!.style.visibility = 'hidden';
            }
        };
        // };
        window.addEventListener('visibilitychange', focus);
        return () => {
            localStorage.removeItem('GT');
            window.removeEventListener('visibilitychange', focus);
        };
    }, [isLogged]);

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    width: '100%',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    background: 'rgba(255,255,255, 0.5)',
                    zIndex: 100000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden'
                }}
                ref={refPopup}>
                <Box
                    sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        background: theme.palette.background.default,
                        borderRadius: ' 2px',
                        padding: '10px',
                        display: 'grid',
                        gridTemplateRows: 'auto auto auto',
                        gap: '15px',
                        justifyItems: 'center'
                    }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography>{globalPopup.checkTime.content}</Typography>
                        <Typography ref={refTimer}>60</Typography>
                    </Box>

                    <Typography> {globalPopup.checkTime.content2}</Typography>
                    <Button
                        sx={{
                            border: '1px solid black'
                        }}
                        onClick={handleUpdateTimeSession}>
                        {globalPopup.checkTime.button}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
