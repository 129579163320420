import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MuiBadge from '@mui/material/Badge';

import Avatar from './Avatar';
import withMainPanelList from '../HOC/withMainPanelList';
import { getUnreadMessagesCount } from '../../shared/utils';
import MessageIcon from './MessageIcon';

/**
 * Bezstanowy komponent funkcyjny. Wyświetla listę z użytkownikami
 * Przekazywany do HOC:  [Components/Chat/withMainPanelList]{@link withMainPanelList}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {Object.<string,string>} props.classes - nazwy klas w css
 * @param {Object.<string,object[]>} props.users - słownik z typami użytkowników jako klucze i przefiltrowanymi i posortowanymi użytkownikami jako wartości. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {Function} props.handleClick - otwiera okno czatu
 * @param {object} props.unreadChats - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @returns {ReactComponent}
 * @see [Components/Chat/withMainPanelList]{@link withMainPanelList}
 */
const UsersList = ({ classes, users, handleClick, unreadChats }) => {
    return Object.keys(users).map((key) => (
        <div className={classes.container} key={key}>
            <span className={classes.chip}>{key === 'paneledostepowe' ? 'pozostali' : key}</span>
            {users[key].map((user) => (
                <ListItem
                    button
                    onClick={(e) => handleClick(e, user)}
                    key={user.id}
                    className={classes.nested}>
                    <ListItemAvatar className={classes.list_avatar}>
                        <Avatar avatar={user.avatar} className={classes.avatar} />
                    </ListItemAvatar>
                    <Typography className={classes.listText} noWrap component="div">
                        <MuiBadge
                            className={classes.badge}
                            variant="dot"
                            invisible={!user.activeStatus}>
                            <div>{user.name}</div>
                        </MuiBadge>
                    </Typography>
                    <MessageIcon
                        count={getUnreadMessagesCount(unreadChats, user.roomID)}
                        size="small"
                    />
                </ListItem>
            ))}
        </div>
    ));
};

export default withMainPanelList(UsersList);
