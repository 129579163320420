import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Window from '../components/Window';
import MessagePanel from './MessagePanel';
import MessagesBoard from '../components/MessagesBoard';
import WindowHeader from '../components/WindowHeader';
import HeaderTitle from '../components/HeaderTitle';
import Message from '../components/Message';
import { resizePicturePromise, createLastReadDict } from '../../shared/utils';
import {
    setActiveChatWindow,
    closeChatWindow,
    setError,
    setBottomOffset,
    setScrolledUp,
    setChatWindowLoading,
    setCallback,
    setChatWindowMinimized,
    resetChat,
    setNewMessage
} from '../store/actions';
import { getUserOrGroupInfo, getChatByWindowID, getSelf } from '../store/selectors';
import useWindowState from '../../shared/hooks/useWindowState';
import usePrevious from '../../shared/hooks/usePrevious';
import { emitSocketEvent } from '../sockets';

const useStyles = makeStyles(() => ({
    content: {
        display: (props) => (props.showContent ? 'flex' : 'none'),
        position: 'relative',
        flexDirection: 'column',
        flexGrow: 1,
        boxSizing: 'border-box'
    }
}));

/**
 * Stanowy komponent funkcyjny podłączony do Redux Strore. Wyświetla okno konkretnego czatu.
 * Wyświetla komponenty  [Components/Chat/Window]{@link Window}, [Components/Chat/WindowHeader]{@link WindowHeader}, [Components/Chat/HeaderTitle]{@link HeaderTitle}, [Components/Chat/MessagePanel]{@link MessagePanel}, [Components/Chat/MessagesBoard]{@link MessagesBoard}, [Components/Chat/Message]{@link Message}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {number} props.windowOrder - numer okna od prawej strony
 * @param {string} props.activeWindow - id aktywnego oktna. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.handleFocus - zobacz [setActiveChatWindow]{@link ActionCreators.setActiveChatWindow}
 * @param {string} props.id - id okna czatu
 * @param {Function} props.closeChatWindow - zobacz [closeChatWindow]{@link ActionCreators.closeChatWindow}
 * @param {object} props.chatInfo - info o grupie lub użytkowniku. Zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {Function} props.setError - zobacz [setError]{@link ActionCreators.setError}
 * @param {object|null} props.chat - Obiekt czatu. zobacz w [Redux/Store/ChatsSlice]{@link Store.ChatsSlice}
 * @param {object|null} props.self - obiekt z informacjami o użytkowniku
 * @param {string} props.self.name - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.self.activeStatus - zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.self.id - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {string} props.self.roomID - zobacz w [Redux/Store/UserSlice]{@link Store.UserSlice}
 * @param {Object.<string,object>} props.users - słownik id użytkowników jako klucze i użytkownikami jako wartości. Zobacz też w [Store.UserSlice]{@link Store.UserSlice}
 * @param {boolean} props.appLoading stan ładowania aplikacji. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.node_url url serwera nodejs. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {string} props.system_url url systemu. Zobacz w [Redux/Store/AppSlice]{@link Store.AppSlice}
 * @param {Function} props.setNewMessage - zobacz [setNewMessage]{@link ActionCreators.setNewMessage}
 * @param {Function} props.setScrolledUp - zobacz [setScrolledUp]{@link ActionCreators.setScrolledUp}
 * @param {Function} props.setChatWindowLoading - zobacz [setChatWindowLoading]{@link ActionCreators.setChatWindowLoading}
 * @param {Function} props.setCallback - zobacz [setCallback]{@link ActionCreators.setCallback}
 * @param {Function} props.setChatWindowMinimized - zobacz [setChatWindowMinimized]{@link ActionCreators.setChatWindowMinimized}
 * @param {Function} props.resetChat - zobacz [resetChat]{@link ActionCreators.resetChat}
 * @property {boolean} minimized - stan znimizalizowania okna.
 * @property {Function} togleMinimize - Funkcja ustawiania minimized.
 * @property {boolean} showContent -  stan pomocniczy minimalizacji okna, używany w css
 * @property {Funtion} setShowContent - funckja ustawiania showContent
 * @returns {ReactComponent}
 * @see [Components/Chat/Window]{@link Window}, [Components/Chat/WindowHeader]{@link WindowHeader}, [Components/Chat/HeaderTitle]{@link HeaderTitle}, [Components/Chat/MessagePanel]{@link MessagePanel}, [Components/Chat/MessagesBoard]{@link MessagesBoard}, [Components/Chat/Message]{@link Message}
 */
export const ChatWindow = ({
    windowOrder,
    activeWindow,
    handleFocus,
    id,
    closeChatWindow,
    chatInfo,
    setError,
    chat,
    self,
    users,
    appLoading,
    node_url,
    system_url,
    setNewMessage,
    setScrolledUp,
    setChatWindowLoading,
    setCallback,
    setChatWindowMinimized,
    resetChat
}) => {
    const [showContent, setShowContent] = useState(true);
    const [minimized, togleMinimize] = useWindowState(id, setShowContent);
    const [newMsgInfo, setNewMsgInfo] = useState(false);
    const classes = useStyles({ showContent });
    const boardRef = useRef();
    const previousChat = usePrevious(chat);
    let {
        messages,
        index: firstLoaded,
        scrolledUp,
        bottomOffset,
        loading,
        newMessage,
        chatID,
        id: roomID,
        lastReadMessages
    } = chat ?? {};
    const lastRead = createLastReadDict(lastReadMessages);
    messages = messages ?? [];
    firstLoaded = firstLoaded ?? null;

    /* When newMessage set interval to blink new message info in a header */
    useEffect(() => {
        let blinkID;
        if (newMessage) {
            blinkID = setInterval(() => setNewMsgInfo((prev) => !prev), 1000);
        }
        return () => {
            setNewMsgInfo(false);
            clearInterval(blinkID);
        };
    }, [newMessage]);

    /* Kontroluje ustawianie czatu jako zminimalizowanego, tylko po pierwszym załadowaniu okna, potrzebne do otworzenia stanu czatu po reloadzie okna*/
    useEffect(() => {
        if (chat && minimized && !previousChat) {
            setChatWindowMinimized(chat.id, true);
        }
    }, [chat, minimized, previousChat, setChatWindowMinimized]);

    /**
     * @memberof ChatWindow
     * @method toggleMinimize
     * @description Ustawia stan okna na zminimalizowany dodatkowo ustawia stan showContect Jeśli okno jest rozwijane, skroluje do ostatniej wiadomosci i usuwa newMessage.
     * @return {void}
     */
    const toggleMinimize = () => {
        if (chat) {
            setChatWindowMinimized(chatInfo.roomID, !minimized);
        }

        if (minimized) {
            setTimeout(() => {
                setShowContent((prev) => !prev);
                if (chat) {
                    scrollToPosition();
                    setScrolledUp(chat.id, false);
                    if (newMessage) {
                        setNewMessage(chat.id, false);
                    }
                }
            }, 400);
        } else {
            setShowContent((prev) => !prev);
        }

        togleMinimize();
    };

    /**
     * @memberof ChatWindow
     * @method handleWindowClose
     * @description Zamyka okno, resetuje czat. Wysyła informacje do serwera o zamknięciu czatu. Wywołuje [client_close_chat]{@link SocketClientEvents.client_close_chat}.
     * @return {void}
     */
    const handleWindowClose = () => {
        if (chat) {
            const { chatID, id } = chat;

            resetChat(id);
            emitSocketEvent('close_chat', { chatID });
        }
        closeChatWindow(id);
    };

    /**
     * @memberof ChatWindow
     * @method handleWindowClick
     * @description Ustawia okno na aktywne jeśli nie jest już ustawione.
     * @return {void}
     */
    const handleWindowClick = () => {
        if (activeWindow !== id) handleFocus(id);
    };

    /**
     * @memberof ChatWindow
     * @method createChat
     * @param {Function} successAction - funkcja do wykonania po zwróceniu przez serwer informacji o sukcesie.
     * @description Obsługuje upload pliku, sprawdza wielkość pliku, przycina rozmiar zdjęć. Przesyła plik na serwer. Ustawia stan ładowania 	 okna czatu.
     * Wywołuje [client_create_chat]{@link SocketClientEvents.client_create_chat}.
     * @return {void}
     */
    const createChat = useCallback(
        (successAction) => {
            const chatType = chatInfo.type === 'group' ? chatInfo.type : 'private';
            const name = chatType === 'group' ? chatInfo.name : null;
            let users;

            if (chatType === 'private') {
                users = [chatInfo.id];
            } else {
                users = chatInfo.users;
            }
            emitSocketEvent(
                'create_chat',
                { chatType, name, users, windowID: id },
                { successAction }
            );
        },
        [chatInfo, id]
    );

    /**
     * @memberof ChatWindow
     * @method handleFile
     * @param {File} file - plik do uploadowania
     * @param {string} roomID - identyfikator czatu
     * @description Obsługuje upload pliku, sprawdza wielkość pliku, przycina rozmiar zdjęć. Przesyła plik na serwer. Ustawia stan ładowania okna czatu.
     * Wywołuje [client_file]{@link SocketClientEvents.client_file} i [client_create_chat]{@link SocketClientEvents.client_create_chat}.
     * @return {void}
     */
    const handleFile = useCallback(
        async (file, roomID) => {
            try {
                if (!file) {
                    return;
                }

                if (file.size > 10485760) {
                    // 10mb
                    return setError({
                        type: 'Plik za duży.',
                        message: 'Rozmiar pliku przekracza 10mb.'
                    });
                }

                const type = file.type.match(/^image\//) ? 'image' : 'file';
                const name = file.name;
                setChatWindowLoading(roomID, true);

                if (type === 'image') {
                    file = await resizePicturePromise(file);
                }

                const errorAction = (error) => {
                    if (error) {
                        setError(error);
                    }
                    setChatWindowLoading(roomID, false);
                };

                if (chat) {
                    const data = {
                        file: {
                            buffer: file,
                            type: file.type,
                            name
                        },
                        type,
                        chatID: chat.chatID
                    };

                    emitSocketEvent('file', data, {
                        successAction: () => setChatWindowLoading(roomID, false),
                        errorAction
                    });
                } else {
                    const successAction = (chatID) => {
                        const data = {
                            file: {
                                buffer: file,
                                type: file.type,
                                name
                            },
                            type,
                            chatID
                        };

                        emitSocketEvent('file', data, {
                            successAction: () => setChatWindowLoading(roomID, false),
                            errorAction
                        });
                    };

                    createChat(successAction);
                }
            } catch (err) {
                setChatWindowLoading(roomID, false);
                return setError({
                    type: 'Błąd przetwarzania',
                    message: 'Wystąpił błąd podczas przetwarzania pliku'
                });
            }
        },
        [setChatWindowLoading, createChat, setError, chat]
    );

    /**
     * @memberof ChatWindow
     * @method scrollToPosition
     * @description Przeskrolowuje okno z wiadomośćiami po załadowaniu wiadomości do tej samej pozycji.
     * @return {void}
     */
    const scrollToPosition = useCallback(() => {
        const el = boardRef.current;
        const scrolledUp = el.scrollHeight - (el.scrollTop + el.clientHeight) > 20;

        if (el && !scrolledUp) {
            el.scrollTop = el.scrollHeight;
        } else if (el && scrolledUp) {
            const elHeight = el.clientHeight;
            const elTotalHeight = el.scrollHeight;
            const scrollTop = elTotalHeight - elHeight - bottomOffset;

            el.scrollTop = scrollTop;
        }
    }, [boardRef, bottomOffset]);

    /**
     * @memberof ChatWindow
     * @method scrollToBottom
     * @description Przeskrolowuje okno do dołu.
     * @return {void}
     */
    const scrollToBottom = useCallback(() => {
        const el = boardRef.current;
        el.scrollTop = el.scrollHeight - el.clientHeight;
    }, [boardRef]);

    return (
        <Window
            minimized={minimized}
            windowOrder={windowOrder}
            id={id}
            windowOpen={true}
            activeWindow={activeWindow}
            handleFocus={handleWindowClick}>
            <WindowHeader
                minimized={minimized}
                toggleMinimize={toggleMinimize}
                active={activeWindow === id}
                darkBackground={newMsgInfo}
                handleWindowClose={handleWindowClose}>
                <HeaderTitle
                    newMessage={newMessage}
                    activeStatus={chatInfo.activeStatus}
                    newMsgInfo={newMsgInfo}
                    active={activeWindow === id}>
                    {newMsgInfo ? 'Nowa wiadomość' : chatInfo.name}
                </HeaderTitle>
            </WindowHeader>
            <div className={classes.content}>
                <MessagesBoard
                    chatID={chatID}
                    boardRef={boardRef}
                    scrollToBottom={scrollToBottom}
                    handleFile={handleFile}
                    self={self}
                    firstLoaded={firstLoaded}
                    roomID={chatInfo.roomID}
                    scrolledUp={scrolledUp}
                    bottomOffset={bottomOffset}
                    setScrolledUp={setScrolledUp}
                    appLoading={appLoading}
                    setChatWindowLoading={setChatWindowLoading}
                    loading={loading}>
                    {messages.map((msg, i) => (
                        <Message
                            lastRead={lastRead ? lastRead[msg.id] : null}
                            scrollToPosition={scrollToPosition}
                            key={msg.content.slice(0, 10) + msg.created_at + i}
                            message={msg}
                            chatID={chat?.chatID}
                            self={msg.roomID === self.roomID ? self : null}
                            user={users[msg.roomID]}
                            prevMsg={messages[i - 1]}
                            setCallback={setCallback}
                            system_url={system_url}
                            node_url={node_url}
                        />
                    ))}
                </MessagesBoard>
                <MessagePanel
                    minimized={minimized}
                    createChat={createChat}
                    handleFile={handleFile}
                    scrollToBottom={scrollToBottom}
                    id={id}
                    roomID={roomID}
                    active={activeWindow === id}
                    setError={setError}
                    chatInfo={chatInfo}
                    chatID={chatID}
                    loading={loading}
                    appLoading={appLoading}
                    windowOrder={windowOrder}
                />
            </div>
        </Window>
    );
};

const mapStateToProps = (state, props) => {
    const { app, chatUser, system } = state;
    const activeWindow = app.activeWindow,
        chatInfo = getUserOrGroupInfo(props.id)(state),
        chat = getChatByWindowID(props.id)(state),
        self = getSelf(state),
        users = chatUser.users,
        appLoading = app.loading,
        node_url = system.nodeUrl,
        system_url = system.systemUrl;

    return { activeWindow, chatInfo, chat, self, users, appLoading, node_url, system_url };
};

const mapDispatchToProps = {
    handleFocus: setActiveChatWindow,
    closeChatWindow,
    setError,
    setBottomOffset,
    setScrolledUp,
    setChatWindowLoading,
    setCallback,
    setChatWindowMinimized,
    resetChat,
    setNewMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
