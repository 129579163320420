import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import Zoom from '@material-ui/core/Zoom';

import Badge from './Badge';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        position: 'relative'
    },
    icon: {
        color: palette.grey[600]
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Odpowiada za wyświetlanie ikony wiadomości z odznaką z ilośćią nieodczytanych wiadomości.
 * @component
 * @category Components
 * @subcategory Chat
 * @param {object} props - Propsy komponentu
 * @param {number} props.count - liczba do wyświetlenia, jeśli zero komponent nie wyświetli się
 * @param {'small'|'large'} props.size - wielkość odznaki, przekazywana do css
 * @returns {ReactComponent}
 */
const MessageIcon = ({ count, size }) => {
    const classes = useStyles();

    return (
        <Zoom in={!!count} unmountOnExit>
            <div className={classes.root}>
                <EmailIcon className={classes.icon} />
                <Badge count={count} size={size} />
            </div>
        </Zoom>
    );
};

export default MessageIcon;
