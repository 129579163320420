import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';

import SearchField from '../containers/SearchField';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        boxSizing: 'border-box',
        paddingBottom: spacing(1.5),
        minHeight: spacing(1.5)
    },
    container: {
        textAlign: 'center'
    },
    nested: {
        display: 'flex',
        paddingLeft: spacing(1.6),
        paddingBottom: spacing(0.8),
        paddingTop: spacing(0.8)
    },
    list_avatar: {
        '&.MuiListItemAvatar-root': {
            minWidth: spacing(5.6)
        }
    },
    avatar: {
        color: palette.primary.light,
        '&.MuiAvatar-root': {
            width: spacing(4),
            height: spacing(4),
            minWidth: spacing(4),
            fontSize: spacing(1.7)
        }
    },
    chip: {
        display: 'inline-block',
        margin: spacing(1),
        background: palette.grey[500],
        color: palette.text.white,
        borderRadius: spacing(0.6),
        textTransform: 'uppercase',
        fontFamily: '"Lato", sans-serif',
        fontSize: spacing(1.4),
        padding: '6px 12px'
    },
    badge: {
        '& .MuiBadge-badge': {
            top: spacing(0.5),
            right: -spacing(1),
            backgroundColor: palette.info.main
        }
    },
    listText: {
        maxWidth: spacing(23.0),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        fontSize: spacing(1.6),
        color: palette.text.secondary,
        fontWeight: 700
    },
    groupText: {
        fontSize: spacing(1.6),
        textTransform: 'uppercase',
        fontWeight: 700,
        color: palette.text.secondary,
        flexGrow: 1
    },
    groupIcon: {
        color: palette.secondary.main,
        marginRight: spacing(1.2)
    },
    settingIcon: {
        color: palette.secondary.main
    },
    deleteIcon: {
        color: palette.primary.light
    },
    searchItem: {
        display: 'flex',
        background: palette.grey[200],
        marginLeft: spacing(1.6),
        marginRight: spacing(1.6),
        width: 'auto',
        flexGrow: 1
    },
    closeButton: {
        color: palette.primary.light
    },
    searchInput: {
        color: palette.text.secondary,
        flexGrow: 1,
        fontSize: spacing(1.6),
        fontFamily: '"Lato", sans-serif',
        border: 'none !important',
        backgroundColor: palette.grey[200] + ' !important',
        padding: `${spacing(0.4)}px ${spacing(0.8)}px`,
        '&:focus': {
            outline: 'none !important',
            border: 'none !important',
            boxShadow: 'none !important'
        }
    }
}));

/**
 * Bezstanowy komponent wyższego rzędu[HOC]. Odpowiada za poprawne wyświetlanie listy grup i listy użytkowników <br/>
 * Przyjmuje komponenty: [Components/Chat/GroupsList]{@link GroupsList}, [Components/Chat/UsersList]{@link UsersList} <br/>
 * Wyświetla komponent: [Components/Chat/SearchField]{@link SearchField}
 * @component
 * @category Components
 * @subcategory Chat
 * @param {ReactComponent} Component komponent przekazywany do HOC
 * @param {object} props - argument zwracanej funkcji. Propsy przekazane do komponentu
 * @param {'groups'|'users'} props.type - typ przekazanej listy
 * @param {boolean} props.open - czy dana lista jest rozwinięta
 * @param {object} props.classes - obiekt z nazwami klas css
 * @param {object} props.rest - reszta propsów komponentów.
 * @param {Function} props.setActiveStatus - funkcja ustawiania opcji statusu
 * @param {boolean} props.activeStatus - wartość opcji statusu
 * @param {boolean} props.busy - wartość opcji zajętości
 * @param {Function} props.setBusy - funkcja ustawiania opcji zajętości
 * @returns {ReactComponent}
 * @see [Components/Chat/GroupsList]{@link GroupsList}, [Components/Chat/UsersList]{@link UsersList}, [Components/Chat/SearchField]{@link SearchField}
 */
export default function withMainPanelListHOC(Component) {
    return function WithMainPanelList(props) {
        const classes = useStyles(props);
        const { open, type, ...rest } = props;

        return (
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" className={classes.root}>
                    <SearchField classes={classes} type={type} />
                    <Component {...rest} classes={classes} />
                </List>
            </Collapse>
        );
    };
}
