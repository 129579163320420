import { AppThunk } from 'src/store';
import { getUserSetting } from 'src/api/src/shared/fetchUserSetting';
import { getSystemConfig } from 'src/api/src/shared/fetchSystemConfig';
import { getAlerts } from 'src/api/src/shared/fetchAlerts';
import { handleUserApiDataResponse } from '../general/user/userSlice';
import {
    handleSystemConfigApiResponse,
    setInitialDataLoaded,
    setSpinner,
    closeSpinner
} from '../general/system/systemSlice';
import { handleAlertsApiResponse } from '../general/alerts/alertsSlice';
import { NAVIGATION_START_POINT_SET_KEY } from 'src/constants';
import { setLoggedOffUser } from 'src/store/src/general';

// Fetches resources and categories sets them in store, then fetches calendar data
export const loadInitialData =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch, getState): Promise<boolean> => {
        try {
            // await logInDevelopment();
            // Fetch data
            dispatch(setSpinner(true));
            const requests: [
                ReturnType<typeof getUserSetting>,
                ReturnType<typeof getSystemConfig>
            ] = [getUserSetting(), getSystemConfig()];
            // await for them to check if none failed
            const [userSettingData, systemConfigData] = await Promise.all(requests);
            dispatch(closeSpinner());
            // set system config data
            dispatch(handleSystemConfigApiResponse(systemConfigData));

            // set user settings if they are good
            dispatch(handleUserApiDataResponse(userSettingData));
            dispatch(setInitialDataLoaded());
        } catch (e: any) {
            console.error('Initial data load failed:', e.message);
            dispatch(setLoggedOffUser());
            sessionStorage.removeItem(NAVIGATION_START_POINT_SET_KEY);
            window.location.reload();
            return false;
        }
        return true;
    };

export const loadAlert =
    (): AppThunk<Promise<boolean>> =>
    async (dispatch, _): Promise<boolean> => {
        try {
            const requests: [ReturnType<typeof getAlerts>] = [getAlerts()];

            const [alertsData] = await Promise.all(requests);
            dispatch(handleAlertsApiResponse(alertsData));
        } catch (e: any) {
            console.error('Alert load failed:', e.message);
            return false;
        }
        return true;
    };
