import { useState } from 'react';
const defaults = { type: 'session' };

/**
 * @category Hooks
 * @function useStorage
 * @description Hook pobiera wartość przekazanego klucza z localStorage lub session storage, przy ustawianiu nowej wartośći zapisuje ją w storage
 * @param {string} item - klucz do pobrania ze storage,
 * @param {any} initVal - Pierwsza wartość klucza.
 * @param {object} [userOptions] - Pierwsza wartość klucza.
 * @param {'session'|'local'} [userOptions.type] - używany typ storage domyślnie 'session'.
 * @returns {Array<any, Function>} - wartość klucza i funkcja do ustawiania wartości.
 */
export default function useStorage(item, initVal, userOptions) {
    const options = Object.assign({}, defaults, userOptions);
    const { type } = options;

    if (!(type === 'session' || type === 'local')) throw new Error('Incorrect options');

    let temp = window[type + 'Storage'].getItem(item);
    if (!temp) {
        temp = initVal;
        window[type + 'Storage'].setItem(item, temp);
    }
    const [localItem, setState] = useState(JSON.parse(temp));

    const setLocalItem = (newItem) => {
        window[type + 'Storage'].setItem(item, JSON.stringify(newItem));
        setState(newItem);
    };

    return [localItem, setLocalItem];
}
